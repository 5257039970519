import { PositioningType } from '../models/PositioningType';
import { OverLayFields } from '../models/OverlayFields';

export const RotaflexPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StructuralLoading,
    position: { x: 262, y: 104 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 262, y: 142 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 262, y: 180 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesToday,
    position: { x: 262, y: 218 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesYesterday,
    position: { x: 262, y: 256 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 119, y: 224 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 119, y: 273 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RunStatus,
    position: { x: 119, y: 311 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 119, y: 349 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorType,
    position: { x: 170, y: 452 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorLoading,
    position: { x: 199.5, y: 489 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 252, y: 452 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 252, y: 490 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 252, y: 543 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.RodLoading,
    position: { x: 0, y: 609 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.PumpFillage,
    position: { x: 0, y: 647 },
    justification: 'right',
  },
];
