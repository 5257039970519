import { PositioningType } from '../models/PositioningType';
import { OverLayFields } from '../models/OverlayFields';

export const ChemicalInjectionPumpDigiUltraPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CyclesToday,
    position: { x: 289, y: 149 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.IntermittenRealTime,
    position: { x: 289, y: 187 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FirstHead,
    position: { x: 320, y: 369 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 507, y: 12 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RTUAddress,
    position: { x: 507, y: 49.94 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.HostName,
    position: { x: 507, y: 88 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Protocol,
    position: { x: 507, y: 126 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.SecondHead,
    position: { x: 537, y: 369 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ChemicalInjectionInformation,
    position: { x: 3, y: 513 },
    justification: 'left',
  },
];
