import { IDataValueModel } from '../../models/DataValueModel';
import { DisplayStatusStateCssClassMap } from '../asset-status/DisplayStatusStateCssClassMap';
import { DisplayStatusState } from '../asset-status/enums/DisplayStatusState';

export function GetDataOverlayCssClass(dv: IDataValueModel, shouldUseNormalDisplayState = false): string {
  if (!shouldUseNormalDisplayState && dv.displayState === DisplayStatusState.Normal) {
    return 'data-value';
  }

  return 'data-value ' + (DisplayStatusStateCssClassMap.get(dv.displayState) ?? '');
}
