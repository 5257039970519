import React from 'react';
import './OnboardingShell.scss';

interface OnboardingStepsProps {
  header: string;
  description?: string;
}

function OnboardingSteps({ header, description = '' }: OnboardingStepsProps): JSX.Element {
  const descriptionText = description.split('\n').map((line, index) => (
    <div key={index} className='onboarding-steps-description'>
      {line}
    </div>
  ));

  return (
    <>
      <p className='onboarding-steps-header'>{header}</p>
      {descriptionText}
    </>
  );
}

export default OnboardingSteps;
