import React from 'react';
import { ReactComponent as RedArrowLeft } from '../../../../images/red-arrow-left.svg';
import './PressureProfileWellbore.scss';

interface WellboreFillProps {
  topFluidColumn: number;
  bottomFluidColumn: number;
  casingFluidColumn: number;
  showArrow?: boolean;
}

const WellboreFill = ({
  topFluidColumn,
  bottomFluidColumn,
  casingFluidColumn,
  showArrow = false,
}: WellboreFillProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        height: '100%',
      }}
    >
      <div style={{ display: 'flex', height: topFluidColumn }} />
      {showArrow && (
        <div
          className='pressure-profile-wellbore-arrow-container'
          style={{ height: bottomFluidColumn + casingFluidColumn + 20 }}
        >
          <RedArrowLeft />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          height: bottomFluidColumn,
          backgroundColor: '#c3e7f1',
          width: '100%',
        }}
      />
      <div style={{ display: 'flex', height: casingFluidColumn, backgroundColor: '#c3e7f1' }} />
      <div
        style={{
          display: 'flex',
          height: '20px',
          backgroundColor: '#c3e7f1',
        }}
      />
    </div>
  );
};

export default WellboreFill;
