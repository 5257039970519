import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTableViewInitialState } from './GroupStatusService';

interface State {
  loading: boolean;
  tableViews: TableViewList | null | undefined;
  error: string | undefined;
}
interface TableViewList {
  id: string;
  dateCreated: string;
  values: Array<TableView>;
}
interface TableView {
  id: string;
  name: string;
  isSelectedView: boolean;
}

// Async thunk to fetch the initial state data
export const fetchInitialStateAsync = createAsyncThunk('tableViewSlice/fetchInitialState', async () => {
  const data = await fetchTableViewInitialState();
  return data;
});

const initialState: State = {
  loading: false,
  tableViews: null,
  error: undefined,
};

export const tableView = createSlice({
  name: 'tableView',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.tableViews = action.payload;
      })
      .addCase(fetchInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default tableView.reducer;
