import { Switch } from '@headlessui/react';
import React from 'react';
import '../../../../../styles/SwitchOptions.scss';
import './NotificationFilterDropdown.scss';
import { useAppSelector } from '../../../../../hooks/storeHooks';

export interface Option {
  id: number;
  name: string;
}

export interface NotificationFilterDropdownProps {
  options: Option[];
  onOptionChange: (selectedId: number | null) => void;
}

const NotificationFilterDropdown: React.FC<NotificationFilterDropdownProps> = ({ options, onOptionChange }) => {
  const selectedOptionId = useAppSelector((state) => state.notificationFilter.currentFilterId);

  const handleSwitchChange = (option: Option) => {
    if (selectedOptionId == option.id) {
      onOptionChange(null);
    } else {
      onOptionChange(option.id);
    }
  };

  return (
    <div data-testid='notification-filter-dropdown' className='filter-dropdown'>
      {options.map((option) => (
        <div key={option.id} className='filter-item'>
          <Switch
            data-testid={`notification-filter-option-${option.name}`}
            checked={selectedOptionId == option.id}
            onChange={() => handleSwitchChange(option)}
            className={`custom-switch ${selectedOptionId == option.id ? 'checked' : 'unchecked'}`}
          >
            <span
              aria-hidden='true'
              className={`custom-switch-handle ${selectedOptionId == option.id ? 'checked' : ''}`}
            />
          </Switch>
          {option.name}
        </div>
      ))}
    </div>
  );
};

export default NotificationFilterDropdown;
