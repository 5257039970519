import React, { useEffect } from 'react';
import * as am5core from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import { groupStatusWidgetColors } from './GroupStatusWidgets';
import { useAppSelector } from '../../../hooks/storeHooks';

const GroupStatusWidgetChart = ({ chartData, chartId, chartValue, isValueSumVisible }) => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const isDarkMode = themeMode === 'dark';

  useEffect(() => {
    const root = am5core.Root.new(chartId, {
      tooltipContainerBounds: {
        top: 0,
        right: 120,
        bottom: 0,
        left: 0,
      },
    });

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5core.percent(70),
        innerRadius: am5core.percent(64),
      }),
    );

    const groupStatusTooltip = am5core.Tooltip.new(root, {
      pointerOrientation: 'horizontal',
    });

    groupStatusTooltip.label.setAll({
      fill: am5core.color('#FFFFFF'),
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: 1.5,
      oversizedBehavior: 'wrap',
      maxWidth: 190,
    });

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'count',
        categoryField: 'name',
        tooltip: groupStatusTooltip,
      }),
    );

    // Convert each color string to am5core.color
    const colors = groupStatusWidgetColors.map((color) => am5core.color(color.color));

    // Set the colors for the series
    series.get('colors').set('colors', colors);
    // Configuring slices
    series.slices.template.setAll({
      stroke: am5core.color(0xffffff),
      strokeWidth: 2,
    });

    series.data.setAll(chartData);

    // Disabling labels and ticks
    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);

    const totalValue = series.children.push(
      am5core.Label.new(root, {
        text: isValueSumVisible
          ? isDarkMode
            ? `[#fff]{valueSum.formatNumber()}`
            : `[#000]{valueSum.formatNumber()}`
          : isDarkMode
          ? `[#fff]${chartValue}`
          : `[#000]${chartValue}`,
        textAlign: 'center',
        centerX: am5core.p50,
        centerY: am5core.p50,
        fontSize: '18px',
        fontWeight: '400',
        populateText: true,
        fontFamily: 'Mulish',
        oversizedBehavior: 'fit',
      }),
    );

    // Resize totalValue to fit series
    series.onPrivate('radius', function (radius) {
      totalValue.set('maxWidth', radius * 1.5);
    });

    return () => {
      root.dispose();
    };
  }, [chartData, chartId]);

  return <div id={chartId} style={{ width: '200px', height: '161px' }}></div>;
};

export default GroupStatusWidgetChart;
