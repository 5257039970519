import React, { useEffect, useRef } from 'react';
import './Timeline.scss';

interface TimelineToolipProps {
  active: boolean;
  label: string;
  width?: number;
  setTooltipWidth?: (width: number) => void;
}

const TimelineTooltip = ({ active, label, width = 110, setTooltipWidth }: TimelineToolipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tooltipRef.current && setTooltipWidth) {
      setTooltipWidth(tooltipRef.current.offsetWidth);
    }
  }, [label]);

  return (
    <div className='timeline-tooltip' style={{ opacity: active ? 1 : 0, minWidth: `${width}px` }} ref={tooltipRef}>
      {label}
    </div>
  );
};

export default TimelineTooltip;
