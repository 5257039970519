import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCardCoordinatesAsync } from './RodLiftAnalysisService';

interface CardCoordinate {
  x: number;
  y: number;
}

interface CardTrend {
  id: number;
  name: string;
  coordinates: CardCoordinate[];
}

interface Card {
  cardDate: string;
  cardTrends: CardTrend[];
}

interface Cards {
  [cardDate: string]: Card;
}

interface CardCoordinatesState {
  values: Cards;
  assetId: string;
  isLoading: boolean;
}

const fetchRodLiftAnalysisCardCoordinatesAsync = createAsyncThunk(
  'RodLiftAnalysis/fetchCardCoordinates',
  async (input: { assetId: string; cardDate: string }) => {
    const response = await fetchCardCoordinatesAsync(input.assetId, input.cardDate);

    return response;
  },
);

const initialState: CardCoordinatesState = {
  values: {},
  assetId: '',
  isLoading: false,
};

const rodLiftAnalysisCardCoordinatesSlice = createSlice({
  name: 'RodLiftAnalysis/CardCoordinatesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRodLiftAnalysisCardCoordinatesAsync.pending, (state, action) => {
      // If fetching data for new asset id then clear slice state.
      const assetId = action.meta.arg.assetId;
      const existingAssetId = state.assetId;

      if (assetId != existingAssetId) {
        return { ...initialState, isLoading: true };
      }
    });
    builder.addCase(fetchRodLiftAnalysisCardCoordinatesAsync.fulfilled, (state, action) => {
      const cardDate = action.meta.arg.cardDate;
      const assetId = action.meta.arg.assetId;

      const card: Card = {
        cardDate: cardDate,
        cardTrends: action.payload.values.map((x) => {
          return {
            id: x.id,
            name: x.name,
            coordinates: x.coordinates,
          } as CardTrend;
        }),
      };

      state.values[cardDate] = card;
      state.assetId = assetId;
      state.isLoading = false;
    });
    builder.addCase(fetchRodLiftAnalysisCardCoordinatesAsync.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
    });
  },
});

export { fetchRodLiftAnalysisCardCoordinatesAsync };
export default rodLiftAnalysisCardCoordinatesSlice.reducer;
