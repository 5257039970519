import React from 'react';

const BreakLink = ({ color = '#B8C5CC' }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_6365_43377)'>
      <path
        d='M8.38975 2.72819L8.38655 1.06152M13.4154 16.0519L13.4186 17.7185M4.23112 6.90287L2.56445 6.90608M17.5741 11.8772L19.2407 11.874M4.9864 3.49659L3.80563 2.32035M16.8188 15.2835L17.9995 16.4597M10.9117 14.1041L9.1473 15.8752C7.84806 17.1795 5.73751 17.1835 4.43326 15.8843C3.12901 14.5851 3.12495 12.4745 4.42419 11.1703L6.18855 9.3991M15.6166 9.38095L17.381 7.60979C18.6802 6.30554 18.6762 4.19499 17.3719 2.89575C16.0677 1.59651 13.9571 1.60058 12.6579 2.90483L10.8935 4.67599'
        stroke={color}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_6365_43377'>
        <rect width='20' height='20' fill='white' transform='translate(0.88501 0.242676) rotate(-0.110297)' />
      </clipPath>
    </defs>
  </svg>
);

export default BreakLink;
