import React from 'react';
import { Menu, Transition, Switch } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { setEnabledStates } from './ViewOptionsSlice';
import { useAppSelector } from '../../../hooks/storeHooks';

interface ViewOptionsProps {
  label: string;
  options: { id: number; label: string; isForm?: boolean }[];
  buttonContent: React.ReactNode;
  onOptionChange: (id: number, isSelected: boolean, name: string) => void;
  enabledStates: boolean[];
}

const ViewOptions: React.FC<ViewOptionsProps> = ({ label, options, buttonContent, onOptionChange }) => {
  const dispatch = useDispatch();
  const enabledStates = useAppSelector((state) => state.viewOptions.enabledStates);

  React.useEffect(() => {
    if (enabledStates.length === 0) {
      const initialStates = Array.from({ length: options.length }, () => true);
      dispatch(setEnabledStates(initialStates));
    }
  }, [dispatch, enabledStates.length, options.length]);

  const updateEnabledStates = (newStates: boolean[]) => {
    dispatch(setEnabledStates(newStates));
  };

  return (
    <Menu as='div' className='toggle-dropdown-container' data-testid='view-options-component'>
      {({ open }) => (
        <>
          <div>
            <Menu.Button>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {buttonContent}
                <span className={open ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
              </div>
            </Menu.Button>
          </div>

          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 z-10 mt-2 whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus-outline-none'>
              <div className='dropdown-label-container'>
                <p className='option-label'>{label}</p>
              </div>
              <div className='dropdown-switches'>
                {options.map((option, index) => (
                  <div key={index} className='flex items-center'>
                    <Switch.Group as='div' className='flex items-center'>
                      <Switch
                        checked={enabledStates[index]}
                        onChange={(value) => {
                          const newStates = [...enabledStates];
                          newStates[index] = value;
                          updateEnabledStates(newStates);
                          onOptionChange(option.id, value, option.label);
                        }}
                        className={`custom-switch ${enabledStates[index] ? 'checked' : 'unchecked'}`}
                        data-testid={option.label}
                      >
                        <span
                          aria-hidden='true'
                          className={`custom-switch-handle ${enabledStates[index] ? 'checked' : ''}`}
                        />
                      </Switch>
                    </Switch.Group>
                    <div className='dropdown-list'>
                      <span data-testid='view-option-label' className='font-medium text-gray-900'>
                        {option.label}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default ViewOptions;
