export const DiagramTypes = {
  AirBalanced: 0,
  Conventional: 1,
  LowProfile: 2,
  Mark2: 3,
  Pcp: 4,
  ReverseMark: 5,
  Rotaflex: 6,
  Esp: 7,
  PlungerLift: 8,
  Injection: 9,
  GasLift: 10,
  Efm: 11,
  GasLiftManager: 12,
  GasFlowMonitor: 13,
  MultiWellPlungerLift: 14,
  TotalFlowTank: 15,
  LiquidFlowMeter: 16,
  PulseAccumulator: 17,
  RegisterView: 18,
  NOVCoreLiftController: 19,
  HydraulicStrokingUnit: 20,
  UnicoLRP: 21,
  ChemicalInjectionPump: 22,
  ChemicalInjectionPumpDigiUltra: 23,
  PlungerAssistedGasLift: 24,
  Facility: 25,
  ValveControl: 26,
  PID: 27,
  TundraSSi: 28,
  JetPump: 29,
} as const;
