import React from 'react';
import { WellTestItemState } from '../../esp-analysis/WellTestSlice';
import { WellTestData } from '../../gl-analysis/GLAnalysisWellTestSlice';
import { CardDateItemState } from '../../rod-lift-analysis/CardDateSlice';
import TimelineTick from './timeline-tick/TimelineTick';

const HANDLE_PADDING = 2;

export function onDragStart(
  e: React.MouseEvent<HTMLDivElement>,
  isLeft: boolean,
  isBox: boolean,
  draggingRef: React.MutableRefObject<boolean>,
  timelineRef: React.MutableRefObject<HTMLDivElement | null>,
  isDraggingBox: React.MutableRefObject<boolean>,
  initialMouseX: React.MutableRefObject<number>,
  initialStart: React.MutableRefObject<number>,
  moveEventListener: React.MutableRefObject<(e: MouseEvent) => void>,
  mouseUpEventListener: React.MutableRefObject<(e: MouseEvent) => void>,
  startRef: React.MutableRefObject<number>,
  endRef: React.MutableRefObject<number>,
  width: number,
  startDate: Date | null,
  endDate: Date | null,
  dateRangeBoxId: string,
  onChange: (start: Date, end: Date) => void,
  setLeftHandlePosition: React.Dispatch<React.SetStateAction<number>>,
  setRightHandlePosition: React.Dispatch<React.SetStateAction<number>>,
  scrollPixels: number,
) {
  e.stopPropagation();
  draggingRef.current = true;

  if (isBox) {
    document.body.style.cursor = 'grabbing';
    document.getElementById(dateRangeBoxId)!.style.cursor = 'grabbing';

    isDraggingBox.current = true;
    initialMouseX.current = e.clientX;
    initialStart.current = startRef.current;

    moveEventListener.current = (e: MouseEvent) =>
      onDragMove(
        e,
        false,
        false,
        draggingRef,
        timelineRef,
        isDraggingBox,
        initialMouseX,
        initialStart,
        startRef,
        endRef,
        width,
        setLeftHandlePosition,
        setRightHandlePosition,
        scrollPixels,
      );
  } else {
    isDraggingBox.current = false;
    moveEventListener.current = (e: MouseEvent) =>
      onDragMove(
        e,
        isLeft,
        !isLeft,
        draggingRef,
        timelineRef,
        isDraggingBox,
        initialMouseX,
        initialStart,
        startRef,
        endRef,
        width,
        setLeftHandlePosition,
        setRightHandlePosition,
        scrollPixels,
      );
  }

  mouseUpEventListener.current = () =>
    onDragEnd(
      draggingRef,
      isDraggingBox,
      moveEventListener,
      mouseUpEventListener,
      startRef,
      endRef,
      width,
      startDate,
      endDate,
      dateRangeBoxId,
      onChange,
    );

  document.addEventListener('mousemove', moveEventListener.current);
  document.addEventListener('mouseup', mouseUpEventListener.current);
}

function onDragMove(
  e: MouseEvent,
  resizeLeft: boolean,
  resizeRight: boolean,
  draggingRef: React.MutableRefObject<boolean>,
  timelineRef: React.MutableRefObject<HTMLDivElement | null>,
  isDraggingBox: React.MutableRefObject<boolean>,
  initialMouseX: React.MutableRefObject<number>,
  initialStart: React.MutableRefObject<number>,
  startRef: React.MutableRefObject<number>,
  endRef: React.MutableRefObject<number>,
  width: number,
  setLeftHandlePosition: React.Dispatch<React.SetStateAction<number>>,
  setRightHandlePosition: React.Dispatch<React.SetStateAction<number>>,
  scrollPixels: number,
) {
  if (!draggingRef.current) return;

  const timelineBounds = timelineRef.current!.getBoundingClientRect();
  const deltaX = e.clientX - initialMouseX.current;

  if (isDraggingBox.current) {
    let newStart = initialStart.current + deltaX;
    let newEnd = newStart + (endRef.current - startRef.current);
    const boxWidth = endRef.current - startRef.current;

    if (newStart < 0) {
      newStart = 0;
      newEnd = newStart + boxWidth;
    } else if (newEnd > width) {
      newEnd = width;
      newStart = newEnd - boxWidth;
    }

    startRef.current = newStart;
    endRef.current = newEnd;

    setLeftHandlePosition(newStart);
    setRightHandlePosition(width - newEnd);
  } else {
    if (resizeLeft) {
      let newLeftHandlePosition = e.clientX - timelineBounds.left + scrollPixels;
      newLeftHandlePosition = Math.max(0, Math.min(newLeftHandlePosition, width));
      newLeftHandlePosition = Math.min(newLeftHandlePosition, endRef.current - HANDLE_PADDING);

      if (newLeftHandlePosition >= 0 && newLeftHandlePosition <= endRef.current - HANDLE_PADDING) {
        startRef.current = newLeftHandlePosition;
        setLeftHandlePosition(newLeftHandlePosition);
      }
    } else if (resizeRight) {
      const offset = (scrollPixels + scrollPixels - width) / 2;
      let newRightHandlePosition = timelineBounds.right - e.clientX - offset;
      newRightHandlePosition = Math.max(0, Math.min(newRightHandlePosition, width));
      newRightHandlePosition = Math.max(startRef.current + HANDLE_PADDING, width - newRightHandlePosition);

      if (newRightHandlePosition >= startRef.current + HANDLE_PADDING && newRightHandlePosition <= width) {
        endRef.current = newRightHandlePosition;
        setRightHandlePosition(width - newRightHandlePosition);
      }
    }
  }
}

function onDragEnd(
  draggingRef: React.MutableRefObject<boolean>,
  isDraggingBox: React.MutableRefObject<boolean>,
  moveEventListener: React.MutableRefObject<(e: MouseEvent) => void>,
  mouseUpEventListener: React.MutableRefObject<(e: MouseEvent) => void>,
  startRef: React.MutableRefObject<number>,
  endRef: React.MutableRefObject<number>,
  width: number,
  startDate: Date | null,
  endDate: Date | null,
  dateRangeBoxId: string,
  onChange: (start: Date, end: Date) => void,
) {
  draggingRef.current = false;
  isDraggingBox.current = false;

  document.body.style.cursor = 'auto';
  const dateRangeBox = document.getElementById(dateRangeBoxId);
  if (dateRangeBox) {
    dateRangeBox.style.cursor = 'grab';
  }

  document.removeEventListener('mousemove', moveEventListener.current);
  document.removeEventListener('mouseup', mouseUpEventListener.current);

  if (!startDate || !endDate) return;
  const start = new Date(startDate);
  start.setTime(startDate.getTime() + (startRef.current / width) * (endDate.getTime() - startDate.getTime()));
  const end = new Date(startDate);
  end.setTime(startDate.getTime() + (endRef.current / width) * (endDate.getTime() - startDate.getTime()));

  onChange(start, end);
}

export const renderCardDateLines = (
  cards: CardDateItemState[] | WellTestItemState[] | WellTestData[],
  startDate: Date,
  endDate: Date,
  width: number,
) => {
  if (!cards || cards.length === 0 || !startDate || !endDate) return null;

  const totalSpan = endDate.getTime() - startDate.getTime();
  const pixelToTimeRatio = totalSpan / width;
  const minSpanBetweenTicksMs = pixelToTimeRatio * 2; // 2 pixel

  const filteredSortedCards = cards.filter((card) => {
    const cardTime = new Date(card.date).getTime();
    return cardTime >= startDate.getTime() && cardTime <= endDate.getTime();
  });

  let lastTickTime = 0;
  return filteredSortedCards
    .flatMap((card, i) => {
      const cardTime = new Date(card.date).getTime();

      if (i === 0 || Math.abs(cardTime - lastTickTime) > minSpanBetweenTicksMs) {
        lastTickTime = cardTime;

        const position = (cardTime - startDate.getTime()) / totalSpan;

        return <TimelineTick key={i} card={card} index={i} position={position} width={width} />;
      }

      return null;
    })
    .filter((element) => element !== null);
};

export function useDragEventListeners(
  timelineRef: React.MutableRefObject<HTMLDivElement | null>,
  setSelectionRange: React.Dispatch<
    React.SetStateAction<{ start: number | null; end: number | null; startDate: Date | null; endDate: Date | null }>
  >,
  width: number,
  startDate: Date,
  endDate: Date,
  onChange: (start: Date, end: Date) => void,
) {
  const dragStartRef = React.useRef<number>(0);
  const dragging = React.useRef<boolean>(false);

  const handleMouseDown = (e: MouseEvent) => {
    if (!timelineRef.current) return;

    const bounds = timelineRef.current.getBoundingClientRect();
    const startX = e.clientX - bounds.left;

    dragStartRef.current = startX;
    dragging.current = true;
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!dragging.current || !timelineRef.current) return;

    const bounds = timelineRef.current.getBoundingClientRect();
    let currentX = e.clientX - bounds.left;
    currentX = Math.max(0, Math.min(currentX, width));

    const startPixel = Math.min(dragStartRef.current, currentX);
    const endPixel = Math.max(dragStartRef.current, currentX);

    const startPercent = startPixel / width;
    const endPercent = endPixel / width;
    const startTime = startDate.getTime() + startPercent * (endDate.getTime() - startDate.getTime());
    const endTime = startDate.getTime() + endPercent * (endDate.getTime() - startDate.getTime());

    setSelectionRange({
      start: startPixel,
      end: width - endPixel,
      startDate: new Date(startTime),
      endDate: new Date(endTime),
    });
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (!dragging.current || !timelineRef.current) return;

    const bounds = timelineRef.current.getBoundingClientRect();
    let endX = e.clientX - bounds.left;
    endX = Math.max(0, Math.min(endX, width));

    dragging.current = false;

    if (Math.abs(dragStartRef.current - endX) < 5) {
      setSelectionRange({ start: null, end: null, startDate: null, endDate: null });
      return;
    }

    const startTime =
      startDate.getTime() + (Math.min(dragStartRef.current, endX) / width) * (endDate.getTime() - startDate.getTime());
    const endTime =
      startDate.getTime() + (Math.max(dragStartRef.current, endX) / width) * (endDate.getTime() - startDate.getTime());

    onChange(new Date(startTime), new Date(endTime));
  };

  React.useEffect(() => {
    const timelineElement = timelineRef.current;
    timelineElement?.addEventListener('mousedown', handleMouseDown);

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    const preventContextMenu = (e: MouseEvent) => e.preventDefault();
    timelineElement?.addEventListener('contextmenu', preventContextMenu);

    return () => {
      timelineElement?.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [timelineRef, width, startDate, endDate, onChange]);
}
