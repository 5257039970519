import AnalysisInputOutput from '../../common/AnalysisInputOutput/AnalysisInputOutput';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import '../../rod-lift-analysis/RodLiftAnalysis/RodLiftAnalysis.css';
import '../../../styles/Analysis.css';
import XDAnalysis from '../../common/XDAnalysis/XDAnalysis';
import AnalysisResultHeader from '../../common/AnalysisResultHeader/AnalysisResultHeader';
import ViewToggle1 from '../../../images/view toggle-1.svg';
import ViewToggle2 from '../../../images/view toggle-2.svg';

import { ReactComponent as Eye } from '../../../images/eye.svg';
import UIIconButtonToolbar from '../../common/UIIconButtonToolbar/UIIconButtonToolbar';
import WellTestList from '../WellTestList/WellTestList';
import { useParams } from 'react-router-dom';
import { ESPChart, defaultCurveColors } from '../ESPAnalysisChart';
import Tooltip from '../../common/tooltip/ToolTip';
import { Card, RGBColor } from '../../../models/Card';
import { setInitialState as setInitialAnalysisState } from '../ESPAnalysisSlice';
import CardTrendOptions from '../../common/CardTrendOptions/CardTrendOptions';
import ESPChartLegend from '../chart-legend/ESPChartLegend';
import { setInitialState as setInitialCardCoordinatesState } from '../ESPCardCoordinatesSlice';
import { WellTestItemState, setInitialState as setInitialWellTestState } from '../WellTestSlice';
import CardHeader from '../../common/CardHeader/CardHeader';
import Timeline from '../../common/timeline/Timeline';
import { AnalysisTimelineProps } from '../../asset-analysis/AssetAnalysis';
import TimelineDatepicker from '../../common/timeline/TimelineDatepicker';
import ChartSelect from '../../common/charts/chart-select/ChartSelect';
import { ChartType } from '../enums/ChartType';
import PressureProfile from './ESPPressureProfile';
import TimelineIcon from '../../common/timeline/TimelineIcon';
import Loader from '../../common/Loader/Loader';

interface UrlParams {
  assetId: string;
}

interface ViewOption {
  id: number;
  label: string;
  active: boolean;
}

const espCurveViewOptions = [
  { id: 0, label: 'Tornado curves', active: true },
  { id: 9, label: 'Pump curve', active: true },
  { id: 12, label: 'Efficiency curve', active: true },
  { id: 11, label: 'Power curve', active: true },
  { id: 13, label: 'Recommended range curve', active: true },
  { id: 10, label: 'Well performance curve', active: true },
  { id: -6, label: 'Operating point', active: true },
];

const svgTogglePrimary = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.5 5H6.5C5.09987 5 4.3998 5 3.86502 5.27248C3.39462 5.51217 3.01217 5.89462 2.77248 6.36502C2.5 6.8998 2.5 7.59987 2.5 9V10.2817C2.5 11.6818 2.5 12.3819 2.77248 12.9167C3.01217 13.3871 3.39462 13.7695 3.86502 14.0092C4.3998 14.2817 5.09987 14.2817 6.5 14.2817H13.5C14.9001 14.2817 15.6002 14.2817 16.135 14.0092C16.6054 13.7695 16.9878 13.3871 17.2275 12.9167C17.5 12.3819 17.5 11.6818 17.5 10.2817V9C17.5 7.59987 17.5 6.8998 17.2275 6.36502C16.9878 5.89462 16.6054 5.51217 16.135 5.27248C15.6002 5 14.9001 5 13.5 5Z'
      stroke='#0094BD'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const svgToggleSecondary = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32293C4.07354 4.60698 3.6146 5.06024 3.32698 5.61773C3 6.25151 3 7.08118 3 8.74052V10.2595C3 11.9188 3 12.7485 3.32698 13.3823C3.6146 13.9398 4.07354 14.393 4.63803 14.6771C5.27976 15 6.11984 15 7.8 15H16.2C17.8802 15 18.7202 15 19.362 14.6771C19.9265 14.393 20.3854 13.9398 20.673 13.3823C21 12.7485 21 11.9188 21 10.2595V8.74052C21 7.08118 21 6.25151 20.673 5.61773C20.3854 5.06024 19.9265 4.60698 19.362 4.32293C18.7202 4 17.8802 4 16.2 4Z'
      stroke='#687760'
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='4' cy='20' r='1' fill='#B8C5CC' />
    <circle cx='8' cy='20' r='1' fill='#B8C5CC' />
    <circle cx='12' cy='20' r='1' fill='#B8C5CC' />
    <circle cx='16' cy='20' r='1' fill='#B8C5CC' />
    <circle cx='20' cy='20' r='1' fill='#B8C5CC' />
  </svg>
);
const uiIconButtons = [
  {
    key: 0,
    name: 'view-toggle-1',
    active: true,
    image: ViewToggle1,
    tooltip: 'Curve view',
    svgNode: svgTogglePrimary,
  },
  {
    key: 1,
    name: 'view-toggle-2',
    active: false,
    image: ViewToggle2,
    tooltip: 'Timeline view',
    svgNode: svgToggleSecondary,
  },
];

const chartOptions = [
  { id: ChartType.PumpCurve, label: 'Pump curve' },
  { id: ChartType.PressureProfile, label: 'Pressure profile' },
];

interface ESPAnalysisProps {
  timelineProps: AnalysisTimelineProps;
}

const ESPAnalysis = ({ timelineProps }: ESPAnalysisProps) => {
  const analysisStore = useAppSelector((state) => state.espAnalysisResult);
  const cardCoordinatesStore = useAppSelector((state) => state.espAnalysisCardCoordinates);
  const wellTestStore = useAppSelector((state) => state.espAnalysisWellTest);
  const { assetId } = useParams<keyof UrlParams>() as UrlParams;
  const [lastSelectedCardDate, setLastSelectedCardDate] = useState('');
  const [selectedCardTrends, setSelectedCardTrends] = useState<ViewOption[]>(
    espCurveViewOptions.map((option) => ({ id: option.id, label: option.label, active: option.active })),
  );
  const [dateRangeFilteredCardDates, setDateRangeFilteredCardDates] = useState<WellTestItemState[] | null>(null);
  const [uiIconButtonState, setUiIconButtonState] = useState(uiIconButtons);
  const isLoadingWellTests = useAppSelector((state) => state.espAnalysisWellTest.loading);
  const isLoadingCoordinates = useAppSelector((state) => state.espAnalysisCardCoordinates.isLoading);
  const [selectedChartId, setSelectedChartId] = useState(0);
  const [activeButtonState, setActiveButtonState] = useState({});

  const dispatch = useAppDispatch();

  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    isDateLinked,
    showDateLinkIcon,
    setStartDate,
    setEndDate,
    setMinDate,
    setMaxDate,
    onClickLinkIcon,
    setShowDateLinkIcon,
  } = timelineProps;

  useEffect(() => {
    if (!isLoadingWellTests && !isLoadingCoordinates && wellTestStore.values && wellTestStore.values.length > 0) {
      const dates = wellTestStore.values.map((x) => new Date(x.date).getTime());
      const minDate = new Date(Math.min(...dates));
      const maxDate = new Date(Math.max(...dates));

      if (minDate.getTime() == maxDate.getTime()) {
        minDate.setDate(minDate.getDate() - 1);
        maxDate.setDate(maxDate.getDate() + 1);
      }

      setMinDate(minDate);
      setMaxDate(maxDate);
    }
  }, [wellTestStore.values.length, isLoadingWellTests, isLoadingCoordinates]);

  useEffect(() => {
    const newUiIconButtonState = uiIconButtonState.map((button) => {
      if (button.name === 'view-toggle-1') {
        return { ...button, active: true };
      } else {
        return { ...button, active: false };
      }
    });
    setUiIconButtonState(newUiIconButtonState);
  }, [assetId]);

  useEffect(() => {
    if (isDateLinked) {
      const newUiIconButtonState = uiIconButtonState.map((button) => {
        if (button.name === 'view-toggle-2') {
          return { ...button, active: true };
        } else {
          return { ...button, active: false };
        }
      });
      setUiIconButtonState(newUiIconButtonState);
    }
  }, [isDateLinked]);

  useEffect(() => {
    if (uiIconButtonState[0].active) {
      setStartDate(null);
      setEndDate(null);
      setDateRangeFilteredCardDates(null);
      setShowDateLinkIcon(false);
    } else if (uiIconButtonState[1].active) {
      setShowDateLinkIcon(true);
    }
  }, [uiIconButtonState]);

  useEffect(() => {
    if (
      !isLoadingCoordinates &&
      !isLoadingWellTests &&
      startDate &&
      endDate &&
      wellTestStore.values.length > 0 &&
      uiIconButtonState[1].active
    ) {
      const filteredCardDates = wellTestStore.values.filter((x) => {
        const cardDate = new Date(x.date);
        return cardDate >= startDate && cardDate <= endDate;
      });

      setDateRangeFilteredCardDates(filteredCardDates);
    } else {
      setDateRangeFilteredCardDates(null);
    }
  }, [wellTestStore.values, startDate, endDate, isLoadingCoordinates, isLoadingWellTests]);

  useEffect(() => {
    const lastSelectedCardDate =
      wellTestStore.values.find((x) => x.id == wellTestStore.selectedCardIds.slice(-1)[0])?.date ?? '';
    if (lastSelectedCardDate) {
      setLastSelectedCardDate(lastSelectedCardDate);
    }
  }, [wellTestStore.selectedCardIds, wellTestStore.values, analysisStore.espAnalysisCards]);

  // Set initial state when no cards are selected
  useEffect(() => {
    if (
      wellTestStore.selectedCardDates.length == 0 &&
      (analysisStore[lastSelectedCardDate]?.input?.length > 0 ||
        analysisStore[lastSelectedCardDate]?.output?.length > 0 ||
        analysisStore[lastSelectedCardDate]?.gasHandlingInputs?.length > 0 ||
        analysisStore[lastSelectedCardDate]?.gasHandlingOutputs?.length > 0 ||
        analysisStore[lastSelectedCardDate]?.xdiagAnalysis != null)
    ) {
      dispatch(setInitialAnalysisState());
    }
  }, [wellTestStore.selectedCardDates]);

  // Set initial state when selecting a different asset
  useEffect(() => {
    dispatch(setInitialAnalysisState());
    dispatch(setInitialCardCoordinatesState());
    dispatch(setInitialWellTestState());
  }, [assetId]);

  function buttonClicked(id: number) {
    const newArray = [...uiIconButtonState];
    newArray.forEach((button) => {
      if (id == button.key) {
        button.active = true;
      } else {
        button.active = false;
      }
    });
    if (id === 0) {
      setActiveButtonState(uiIconButtonState[0]);
    } else {
      setActiveButtonState(uiIconButtonState[1]);
    }
    setUiIconButtonState(newArray);

    return;
  }

  const getSelectedCards = (): Card[] => {
    if (wellTestStore && wellTestStore.values.length) {
      const cards = wellTestStore.values
        .filter((x) => x.isSelected == true && x.date != null && cardCoordinatesStore.values[x.date] != null)
        .filter((cardDate) =>
          startDate && endDate ? new Date(cardDate.date) >= startDate && new Date(cardDate.date) <= endDate : true,
        );

      if (cards.length == 0) {
        return [];
      }

      return (
        cards.map((x) => {
          return {
            cardDate: cardCoordinatesStore.values[x.date].cardDate,
            cardTrends: cardCoordinatesStore.values[x.date].cardTrends,
            color: x.color,
          } as Card;
        }) ?? []
      );
    }

    return [];
  };

  const createAnalysisResultHeaderDescription = () => {
    const selectedWellTestId = wellTestStore.selectedCardIds.slice(-1)[0];
    const wellTestItem = wellTestStore.values.find((x) => x.id == selectedWellTestId);

    if (wellTestItem) {
      return `${wellTestItem.analysisTypeName} ${wellTestItem.date}`;
    }

    return '';
  };

  const renderPumpCurve = () => {
    return (
      <>
        <ESPChart selectedCardTrends={selectedCardTrends.filter((x) => x.active)} cards={getSelectedCards()} />
        <ESPChartLegend
          legendItems={selectedCardTrends
            .filter((x) => x.active && wellTestStore.values.filter((x) => x.isSelected).length > 0)
            .map((x) => {
              const selectedWellTestDates = wellTestStore.values.filter((x) => x.isSelected);

              let color: RGBColor | undefined = undefined;
              let stringColor: string | null = null;

              const id = x.id;
              // Keep these curve id's the same color, when multiple well tests selected.
              if (id === 0 || id === 13 || id === 14 || id === 15 || id === 16) {
                stringColor = defaultCurveColors.find((x) => x.id === id)?.color ?? null;
              } else {
                color =
                  selectedWellTestDates.length > 0
                    ? selectedWellTestDates[selectedWellTestDates.length - 1].color ?? undefined
                    : undefined;

                if (color) {
                  stringColor = `rgb(${color.r}, ${color.g}, ${color.b})`;
                }
              }

              return {
                id: x.id,
                name: x.label,
                color: stringColor ?? defaultCurveColors.find((cc) => cc.id == x.id)?.color,
              };
            })}
        />
      </>
    );
  };

  const [isPumpDataCollapsed, setIsPumpDataCollapsed] = useState(false);
  const toggleBodyCollapse = () => {
    setIsPumpDataCollapsed((prevIsPumpDataCollapsed) => !prevIsPumpDataCollapsed);
  };

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const handleClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (isFullScreen) {
      htmlElement.classList.add('fullscreen');
    } else {
      htmlElement.classList.remove('fullscreen');
    }
  }, [isFullScreen]);

  return (
    <div className={isFullScreen ? 'full-screen' : ''}>
      <div className={`card-viewer ${isFullScreen ? 'card-viewer-full-screen-override' : ''}`}>
        <CardHeader value='ESP analysis' icon='fullScreen' isFullScreen={isFullScreen} onClick={handleClick} />
        <div className='card-header-divider' />
        <div className='card-viewer-body'>
          <div className='content-header-frame'>
            <UIIconButtonToolbar uiIconButtons={uiIconButtonState} buttonClicked={buttonClicked} />
            <div className='ml-2 select-wrap'>
              <ChartSelect
                chartOptions={chartOptions}
                selectedChartId={selectedChartId}
                setSelectedChartId={setSelectedChartId}
              />
            </div>
            <div data-testid='card-viewer-eye' className='card-viewer-button-container'>
              {uiIconButtonState[1].active && (
                <div className='card-viewer-date-picker-container'>
                  {showDateLinkIcon && (
                    <div onClick={() => onClickLinkIcon(0)}>
                      <TimelineIcon isDateLinked={isDateLinked} />
                    </div>
                  )}
                  <div className='card-calendar-width card-calendar'>
                    <TimelineDatepicker {...timelineProps} />
                  </div>
                </div>
              )}
              <Tooltip content='Curve view options' direction='top'>
                <CardTrendOptions
                  label='View options'
                  options={selectedCardTrends}
                  buttonContent={
                    <div className='card-viewer-button'>
                      <Eye />
                    </div>
                  }
                  onOptionChange={(id, value) => {
                    setSelectedCardTrends((selectedCardTrends) => {
                      const newSelectedCardTrends = [...selectedCardTrends];
                      const index = newSelectedCardTrends.findIndex((x) => x.id == id);
                      newSelectedCardTrends[index].active = value;
                      return newSelectedCardTrends;
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div className='card-viewer-card'>
            <div className='flex flex-row flex-grow flex-shrink'>
              <div className='relative flex flex-grow flex-column'>
                {(analysisStore.loading || cardCoordinatesStore.isLoading) && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 100,
                    }}
                  >
                    <Loader />
                  </div>
                )}
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    opacity: analysisStore.loading || cardCoordinatesStore.isLoading ? 0.5 : 1,
                  }}
                >
                  <>
                    {selectedChartId == ChartType.PumpCurve && renderPumpCurve()}
                    {selectedChartId == ChartType.PressureProfile && (
                      <PressureProfile pressureProfile={analysisStore[lastSelectedCardDate]?.pressureProfile} />
                    )}
                  </>
                </div>
              </div>
              <div className='relative card-viewer-date-list'>
                <WellTestList dateRangeFilteredCardDates={dateRangeFilteredCardDates} assetId={assetId} />
              </div>
            </div>
            {uiIconButtonState[1].active && (
              <Timeline
                cards={wellTestStore.values}
                minDate={minDate}
                maxDate={maxDate}
                selectedStartDate={startDate}
                selectedEndDate={endDate}
                onChange={(startDate, endDate) => {
                  setMinDate(startDate);
                  setStartDate(startDate);
                  setMaxDate(endDate);
                  setEndDate(endDate);
                }}
              />
            )}
          </div>
          <div className='rodliftanalysis-container'>
            <div className={`rodliftanalysis-header ${isPumpDataCollapsed ? 'rodliftanalysis-header-collapsed' : ''}`}>
              <AnalysisResultHeader
                title='Pump curve data'
                description={createAnalysisResultHeaderDescription()}
                isCollapsed={isPumpDataCollapsed}
                toggleCollapse={toggleBodyCollapse}
                loading={!(analysisStore.loading || isLoadingCoordinates)}
              />
            </div>
            {!isPumpDataCollapsed && (
              <div
                className={`${
                  activeButtonState?.name === 'view-toggle-2'
                    ? 'rodliftanalysis-body espanalysis-body-with-timeline'
                    : 'rodliftanalysis-body'
                }`}
              >
                <div className='relative rodliftanalysis-scroll-area'>
                  <>
                    <AnalysisInputOutput
                      loading={analysisStore.loading}
                      headerText='Input'
                      values={analysisStore[lastSelectedCardDate]?.input?.map((x, index) => ({
                        key: index,
                        id: x.id,
                        title: x.displayName,
                        value: x.displayValue,
                      }))}
                    />
                    <AnalysisInputOutput
                      loading={analysisStore.loading}
                      headerText='Output'
                      values={analysisStore[lastSelectedCardDate]?.output?.map((x, index) => ({
                        key: index,
                        id: x.id,
                        title: x.displayName,
                        value: x.displayValue,
                      }))}
                    />
                    {analysisStore[lastSelectedCardDate]?.isGasHandlingEnabled && (
                      <>
                        <AnalysisInputOutput
                          loading={analysisStore.loading}
                          headerText='Gas handling input'
                          values={analysisStore[lastSelectedCardDate]?.gasHandlingInputs?.map((x, index) => ({
                            key: index,
                            id: x.id,
                            title: x.displayName,
                            value: x.displayValue,
                          }))}
                        />
                        <AnalysisInputOutput
                          loading={analysisStore.loading}
                          headerText='Gas handling output'
                          values={analysisStore[lastSelectedCardDate]?.gasHandlingOutputs?.map((x, index) => ({
                            key: index,
                            id: x.id,
                            title: x.displayName,
                            value: x.displayValue,
                          }))}
                        />
                      </>
                    )}
                    <XDAnalysis
                      loading={analysisStore.loading}
                      headerText='Analysis data'
                      value={analysisStore[lastSelectedCardDate]?.xdiagAnalysis?.displayValue ?? ''}
                    />
                  </>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ESPAnalysis;
