import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const HydraulicStrokingUnitPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 75, y: 96 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 75, y: 134 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StructuralLoading,
    position: { x: 75, y: 172 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 75, y: 210 },
    justification: 'left',
  },
  { overlayField: OverLayFields.Protocol, position: { x: 75, y: 248 }, justification: 'left' },
  { overlayField: OverLayFields.HostName, position: { x: 75, y: 288 }, justification: 'left' },
  { overlayField: OverLayFields.Port, position: { x: 75, y: 327 }, justification: 'left' },
  { overlayField: OverLayFields.RTUAddress, position: { x: 75, y: 365 }, justification: 'left' },
  { overlayField: OverLayFields.Offset, position: { x: 75, y: 403 }, justification: 'left' },
  { overlayField: OverLayFields.OPCType, position: { x: 75, y: 441 }, justification: 'left' },
  {
    overlayField: OverLayFields.RunStatus,
    position: { x: 289, y: 246 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 289, y: 277 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 288, y: 565 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpingUnit,
    position: { x: 637, y: 96 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorType,
    position: { x: 637, y: 134 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorLoading,
    position: { x: 637, y: 172 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 637, y: 210 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FlowlinePressure,
    position: { x: 637, y: 248 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 637, y: 288 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpDepth,
    position: { x: 637, y: 513 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpType,
    position: { x: 637, y: 551 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpFillage,
    position: { x: 637, y: 589 },
    justification: 'left',
  },
];
