import React from 'react';
import { useState } from 'react';
import TimelineTooltip from '../TimelineTooltip';

const TimelineTick = ({ card, index, position, width }) => {
  const [showLabel, setShowLabel] = useState(false);
  const [tooltipWidth, setTooltipWidth] = useState(0);

  const calculateTooltipOffset = (timelineWidth, position, tooltipWidth) => {
    let left = timelineWidth * position - tooltipWidth / 2;

    if (left < 0) {
      left = 0;
    }

    if (left + tooltipWidth > timelineWidth) {
      left = timelineWidth - tooltipWidth;
    }

    return left;
  };

  return (
    <div>
      <div
        key={index}
        style={{
          position: 'absolute',
          left: `${position * 100}%`,
          bottom: 0,
          width: card.isSelected ? '2px' : '2px',
          height: card.isSelected ? '16px' : '8px',
          backgroundColor:
            card.isSelected && card.color ? `rgb(${card.color.r}, ${card.color.g}, ${card.color.b})` : '#647980',
          zIndex: 1,
        }}
        onMouseEnter={() => setShowLabel(true)}
        onMouseLeave={() => setShowLabel(false)}
      ></div>
      <div
        className='timeline-tick-line-tooltip-container'
        style={{ left: `${calculateTooltipOffset(width, position, tooltipWidth)}px` }}
      >
        <TimelineTooltip
          active={showLabel}
          label={
            card.cardTypeName +
              ' ' +
              new Date(card.date)?.toLocaleString('en', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }) ?? ''
          }
          setTooltipWidth={setTooltipWidth}
        />
      </div>
    </div>
  );
};

export default TimelineTick;
