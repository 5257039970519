import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  fetchWellControlActionsAsync,
  executeWellControlActionAsync,
  enableWellAsync,
  disableWellAsync,
  fetchEnabledStatusInitialState,
} from './integration/services/AssetDataService';

import { ControlActionUIItem } from './../../features/common/ControlActions/ControlActions';

// State models
interface WellControlAction {
  id: number;
  name: string;
}

interface AssetDataState {
  wellControlActions: WellControlAction[];
  wellControlActionsLoading: boolean;
  wellControlActionsError: string;

  executeControlActionShowSuccess: boolean;
  executeControlActionShowFailure: boolean;
  executeControlActionErrorMessage: string | undefined;
  executeControlActionMessage: string;
  executeControlActionOnReturnSuccess: boolean;
  executeControlActionOnReturnSuccessMessage: string;
  executeControlActionOnReturnError: boolean;
  executeControlActionOnReturnMessage: string;
  wellEnabledStatus: boolean | null;
  wellEnabledStatusLoading: boolean;
  wellEnabledStatusError: string | undefined;

  togglingWellEnabledStatus: boolean;
  togglingWellEnabledStatusError: string | undefined;

  canConfigWell: boolean;
  previousControlModeAction: ControlActionUIItem;
}

// Thunks
export const fetchWellControlActions = createAsyncThunk(
  'AssetData/fetchWellControlActions',
  async (assetId: string) => {
    const response = await fetchWellControlActionsAsync(assetId);

    return response;
  },
);

interface ExecuteWellControlActionArgs {
  assetId: string;
  controlType: number;
  socketId: string;
  controlActionName: string;
  wellName: string | undefined;
}

export const executeWellControlAction = createAsyncThunk(
  'AssetData/executeWellControlAction',
  async (args: ExecuteWellControlActionArgs) => {
    const { assetId, controlType, socketId, controlActionName, wellName } = args;
    return await executeWellControlActionAsync(assetId, controlType, socketId, controlActionName, wellName);
  },
);

export const fetchEnabledStatus = createAsyncThunk('AssetData/fetchEnabledStatus', async (assetId: string) => {
  const response = await fetchEnabledStatusInitialState(assetId);

  return response;
});

export const enableWell = createAsyncThunk(
  'AssetData/enableWell',
  async (args: { assetId: string; socketId: string }) => {
    const response = await enableWellAsync(args.assetId, args.socketId);

    return response;
  },
);

export const disableWell = createAsyncThunk(
  'AssetData/disableWell',
  async (args: { assetId: string; socketId: string }) => {
    const response = await disableWellAsync(args.assetId, args.socketId);

    return response;
  },
);

const initialState: AssetDataState = {
  wellControlActions: [],
  wellControlActionsLoading: false,
  wellControlActionsError: '',
  executeControlActionOnReturnError: false,
  executeControlActionOnReturnMessage: '',
  executeControlActionShowSuccess: false,
  executeControlActionShowFailure: false,
  executeControlActionErrorMessage: undefined,
  executeControlActionMessage: '',

  executeControlActionOnReturnSuccess: false,
  executeControlActionOnReturnSuccessMessage: '',

  wellEnabledStatus: null,
  wellEnabledStatusLoading: false,
  wellEnabledStatusError: undefined,

  togglingWellEnabledStatus: false,
  togglingWellEnabledStatusError: undefined,
  canConfigWell: false,
  previousControlModeAction: null,
};

// Slice
export const assetDataSlice = createSlice({
  name: 'AssetData',
  initialState,
  reducers: {
    setExecuteControlActionOnReturnSuccessful: (state, action) => {
      state.executeControlActionOnReturnSuccess = true;
      state.executeControlActionOnReturnSuccessMessage = action.payload.message;
    },
    setExecuteControlActionOnReturnError: (state, action) => {
      state.executeControlActionOnReturnError = true;
      state.executeControlActionOnReturnMessage = action.payload.message;
    },
    setPreviousControlMode: (state, action) => {
      state.previousControlModeAction = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchEnabledStatus thunk cases
    builder
      // fetchWellControlActions thunk cases
      .addCase(fetchWellControlActions.pending, (state) => {
        state.wellControlActionsLoading = true;
        state.wellControlActions = [];
      })
      .addCase(fetchWellControlActions.fulfilled, (state, action) => {
        state.wellControlActionsLoading = false;

        const wellControlActions: WellControlAction[] = action.payload.wellControlActions.map(
          (wellControlActionFromResponse) => ({
            id: wellControlActionFromResponse.id,
            name: wellControlActionFromResponse.name,
          }),
        );

        state.wellControlActions = wellControlActions;
        state.canConfigWell = action.payload.canConfigWell;
      })
      .addCase(fetchWellControlActions.rejected, (state, action) => {
        state.wellControlActionsLoading = false;
        state.wellControlActionsError = action.error.message ?? '';
      })

      // executeWellControlAction thunk cases
      .addCase(executeWellControlAction.pending, (state) => {
        state.executeControlActionShowSuccess = false;
        state.executeControlActionShowFailure = false;
        state.executeControlActionOnReturnSuccess = false;
        state.executeControlActionOnReturnError = false;
      })
      .addCase(executeWellControlAction.fulfilled, (state, action) => {
        state.executeControlActionShowSuccess = true;
        state.executeControlActionMessage = action.payload;
      })
      .addCase(executeWellControlAction.rejected, (state, action) => {
        state.executeControlActionShowFailure = true;
        state.executeControlActionErrorMessage = action.error.message;
      })

      // fetchEnabledStatus thunk cases
      .addCase(fetchEnabledStatus.pending, (state) => {
        state.wellEnabledStatus = null;
        state.wellEnabledStatusLoading = true;
      })
      .addCase(fetchEnabledStatus.fulfilled, (state, action) => {
        state.wellEnabledStatus = action.payload.enabled;
        state.wellEnabledStatusLoading = false;
      })
      .addCase(fetchEnabledStatus.rejected, (state, action) => {
        state.wellEnabledStatusError = action.error.message;
        state.wellEnabledStatusLoading = false;
      })

      // enableWell thunk cases
      .addCase(enableWell.pending, (state) => {
        state.togglingWellEnabledStatus = true;
        state.executeControlActionOnReturnSuccess = false;
        state.executeControlActionOnReturnError = false;
      })
      .addCase(enableWell.fulfilled, (state) => {
        state.wellEnabledStatus = true;
        state.togglingWellEnabledStatus = false;
      })
      .addCase(enableWell.rejected, (state, action) => {
        state.togglingWellEnabledStatus = false;
        state.togglingWellEnabledStatusError = action.error.message;
      })

      // disableWell thunk cases
      .addCase(disableWell.pending, (state) => {
        state.togglingWellEnabledStatus = true;
        state.executeControlActionOnReturnSuccess = false;
        state.executeControlActionOnReturnError = false;
      })
      .addCase(disableWell.fulfilled, (state) => {
        state.wellEnabledStatus = false;
        state.togglingWellEnabledStatus = false;
      })
      .addCase(disableWell.rejected, (state, action) => {
        state.togglingWellEnabledStatus = false;
        state.togglingWellEnabledStatusError = action.error.message;
      });
  },
});

export const selectAssetData = (state: RootState) => state.assetData;
export const {
  setExecuteControlActionOnReturnSuccessful,
  setExecuteControlActionOnReturnError,
  setPreviousControlMode,
} = assetDataSlice.actions;

export default assetDataSlice.reducer;
