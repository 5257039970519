import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchGroupDowntimeByWellsInitialState,
  fetchGroupDowntimeByRunStatusInitialState,
  fetchGroupAlarmsWidgetDataInitialState,
  fetchGroupClassificationWidgetDataInitialState,
} from '../GroupStatusService';

interface DowntimeByWellsAssets {
  id: string;
  name: string;
  count: number;
  percent: number;
}

interface DowntimeGroupByDuration {
  id: string;
  name: string;
  count: number;
  percent: number;
}

interface DowntimeByWells {
  assets: DowntimeByWellsAssets[];
  groupByDuration: DowntimeGroupByDuration[];
}

interface DowntimeByRunStatus {
  id: string;
  name: string;
  count: number;
  percent: number;
}

interface State {
  alarmsLoading: boolean;
  classificationsLoading: boolean;
  downtimeByWellsLoading: boolean;
  downtimeByRunStatusLoading: boolean;
  alarmsValues: AlarmValues | null;
  classificationValues: ClassificationValue[];
  downtimeByWells: DowntimeByWells;
  downtimeByRunStatus: DowntimeByRunStatus[];
  alarmsError: string | undefined;
  classificationsError: string | undefined;
  downtimeByWellsError: string | undefined;
  downtimeByRunStatusError: string | undefined;
}
interface AlarmValues {
  id: string;
  dateCreated: string;
  values: Alarm;
}

interface Alarm {
  id: string;
  name: string;
  count: number;
  percent: number;
}

interface ClassificationValue {
  id: string;
  dateCreated: string;
  assetCount: number;
  classificationValues: Classification[];
}
interface Classification {
  id: string;
  name: string;
  hours: number;
  percent: number;
  count: number;
}

export const fetchGroupAlarmsWidgetDataAsync = createAsyncThunk(
  'groupWidgetSlice/fetchGroupAlarmsWidgetData',
  async (groupName: string) => {
    const alarmsData = await Promise.all([fetchGroupAlarmsWidgetDataInitialState(groupName)]);
    return { alarmsData };
  },
);

export const fetchGroupClassificationWidgetDataAsync = createAsyncThunk(
  'groupWidgetSlice/fetchGroupClassificationWidgetData',
  async (groupName: string) => {
    const classificationsData = await Promise.all([fetchGroupClassificationWidgetDataInitialState(groupName)]);
    return { classificationsData };
  },
);

export const fetchGroupDowntimeByWellsAsync = createAsyncThunk(
  'groupWidgetSlice/fetchGroupDowntimeByWellsData',
  async (groupName: string) => {
    const downtimeByWellsData = await fetchGroupDowntimeByWellsInitialState(groupName);
    return { downtimeByWellsData };
  },
);

export const fetchGroupDowntimeByRunStatusAsync = createAsyncThunk(
  'groupWidgetSlice/fetchGroupDowntimeByRunStatusData',
  async (groupName: string) => {
    const downtimeByRunStatusData = await fetchGroupDowntimeByRunStatusInitialState(groupName);
    return { downtimeByRunStatusData };
  },
);

const initialState: State = {
  alarmsLoading: false,
  classificationsLoading: false,
  downtimeByWellsLoading: false,
  downtimeByRunStatusLoading: false,
  alarmsValues: null,
  classificationValues: [],
  downtimeByWells: {
    assets: [],
    groupByDuration: [],
  },
  downtimeByRunStatus: [],
  alarmsError: undefined,
  classificationsError: undefined,
  downtimeByWellsError: undefined,
  downtimeByRunStatusError: undefined,
};

export const groupWidgetSlice = createSlice({
  name: 'groupWidget',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupAlarmsWidgetDataAsync.pending, (state) => {
        state.alarmsLoading = true;
      })
      .addCase(fetchGroupAlarmsWidgetDataAsync.fulfilled, (state, action) => {
        state.alarmsLoading = false;
        state.alarmsValues = action.payload.alarmsData[0];
      })
      .addCase(fetchGroupAlarmsWidgetDataAsync.rejected, (state, action) => {
        state.alarmsLoading = false;
        state.alarmsError = action.error.message;
      })
      .addCase(fetchGroupClassificationWidgetDataAsync.pending, (state) => {
        state.classificationsLoading = true;
      })
      .addCase(fetchGroupClassificationWidgetDataAsync.fulfilled, (state, action) => {
        state.classificationsLoading = false;
        state.classificationValues = action.payload.classificationsData;
      })
      .addCase(fetchGroupClassificationWidgetDataAsync.rejected, (state, action) => {
        state.classificationsLoading = false;
        state.classificationsError = action.error.message;
      })
      .addCase(fetchGroupDowntimeByWellsAsync.pending, (state) => {
        state.downtimeByWellsLoading = true;
      })
      .addCase(fetchGroupDowntimeByWellsAsync.fulfilled, (state, action) => {
        state.downtimeByWellsLoading = false;
        state.downtimeByWells = action.payload.downtimeByWellsData.values;
      })
      .addCase(fetchGroupDowntimeByWellsAsync.rejected, (state, action) => {
        state.downtimeByWellsLoading = false;
        state.downtimeByWellsError = action.error.message;
      })
      .addCase(fetchGroupDowntimeByRunStatusAsync.pending, (state) => {
        state.downtimeByRunStatusLoading = true;
      })
      .addCase(fetchGroupDowntimeByRunStatusAsync.fulfilled, (state, action) => {
        state.downtimeByRunStatusLoading = false;
        state.downtimeByRunStatus = action.payload.downtimeByRunStatusData;
      })
      .addCase(fetchGroupDowntimeByRunStatusAsync.rejected, (state, action) => {
        state.downtimeByRunStatusLoading = false;
        state.downtimeByRunStatusError = action.error.message;
      });
  },
});

export default groupWidgetSlice.reducer;
