import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

export enum AxisDefault {
  Min = 0,
  Max = 1,
}

export const setGridDefault = (axis: am5xy.ValueAxis<am5xy.AxisRenderer>) => {
  const axisRenderer = axis.get('renderer');
  axisRenderer.grid.template.setAll({
    strokeOpacity: 1,
    stroke: am5core.color('#D0D8DD'),
    strokeWidth: 1,
  });

  return axisRenderer;
};

export const createYAxisLabelDefault = (
  root: am5core.Root,
  text: string,
  seriesColor: am5core.Color | undefined = undefined,
): am5core.Label => {
  const label = am5core.Label.new(root, {
    text: text,
    centerX: am5core.p50,
    y: am5core.p50,
    centerY: am5core.p50,
    rotation: 270,
    fontSize: 12,
    fontWeight: '400',
    fill: seriesColor,
  });

  return label;
};

export const createChartDefault = (root: am5core.Root) => {
  const chart = am5xy.XYChart.new(root, {
    panX: true,
    panY: true,
    wheelY: 'zoomXY',
    maxTooltipDistance: -1,
    width: am5core.percent(100),
  });

  return chart;
};

export const createTooltipDefault = (root: am5core.Root) => {
  const tooltip = am5core.Tooltip.new(root, {
    labelText: '{name}\nX: {valueX}\nY: {valueY}',
    getFillFromSprite: false,
    getLabelFillFromSprite: false,
    autoTextColor: false,
  });

  return tooltip;
};

export const setTooltipDefault = (tooltip: am5core.Tooltip, seriesColor: am5core.Color) => {
  tooltip.get('background')?.set('fill', seriesColor);
  tooltip.label.setAll({
    fill:
      seriesColor.r * 0.299 + seriesColor.g * 0.587 + seriesColor.b * 0.114 > 150
        ? am5core.color('#000')
        : am5core.color('#fff'),
    fontSize: 12,
    fontWeight: '400',
  });
};
