import { DiagramTypes } from './models/DiagramTypes';
import { AirBalancedPositioning } from './overlay-positioning/AirBalancedPositioning';
import { ChemicalInjectionPumpDigiUltraPositioning } from './overlay-positioning/ChemicalInjectionPumpDigiUltraPositioning';
import { ChemicalInjectionPumpPositioning } from './overlay-positioning/ChemicalInjectionPumpPositioning';
import { ConventionalPositioning } from './overlay-positioning/ConventionalPositioning';
import { ESPPositioning } from './overlay-positioning/ESPPositioning';
import { FacilityPositioning } from './overlay-positioning/FacilityPositioning';
import { GasLiftManagerPositioning } from './overlay-positioning/GasLiftManagerPositioning';
import { GasLiftPositioning } from './overlay-positioning/GasLiftPositioning';
import { EFMPositioning } from './overlay-positioning/EFMPositioning';
import { HydraulicStrokingUnitPositioning } from './overlay-positioning/HydraulicStrokingUnitPositioning';
import { InjectionPositioning } from './overlay-positioning/InjectionPositioning';
import { LiquidFlowMeterPositioning } from './overlay-positioning/LiquidFlowMeterPositioning';
import { LowProfilePositioning } from './overlay-positioning/LowProfilePositioning';
import { Mark2Positioning } from './overlay-positioning/Mark2Positioning';
import { MultiWellPlungerLiftPositioning } from './overlay-positioning/MultiWellPlungerLiftPositioning';
import { NOVCoreLiftPositioning } from './overlay-positioning/NOVCoreLiftPositioning';
import { PCPPositioning } from './overlay-positioning/PCPPositioning';
import { PlungerLiftPositioning } from './overlay-positioning/PlungerLiftPositioning';
import { RotaflexPositioning } from './overlay-positioning/RotaflexPositioning';
import { ReverseMarkPositioning } from './overlay-positioning/ReverseMarkPositioning';
import { TotalFlowTankPositioning } from './overlay-positioning/TotalFlowTankPositioning';
import { TundraSSIPositioning } from './overlay-positioning/TundraSSIPositioning';
import { UnicoLRPPositioning } from './overlay-positioning/UnicoLRPPositioning';
import { JetPumpPositioning } from './overlay-positioning/JetPumpPositioning';
import { PulseAccumulatorPositioning } from './overlay-positioning/PulseAccumulatorPositioning';
import { ValveControlPositioning } from './overlay-positioning/ValveControlPositioning';
import { GasFlowMonitorPositioning } from './overlay-positioning/GasFlowMonitorPositioning';
import { RegisterViewPositioning } from './overlay-positioning/RegisterViewPositioning';
import { PIDPositioning } from './overlay-positioning/PIDPositioning';

export const DiagramPositioning = {
  [DiagramTypes.AirBalanced]: AirBalancedPositioning,
  [DiagramTypes.Conventional]: ConventionalPositioning,
  [DiagramTypes.LowProfile]: LowProfilePositioning,
  [DiagramTypes.Mark2]: Mark2Positioning,
  [DiagramTypes.Pcp]: PCPPositioning,
  [DiagramTypes.ReverseMark]: ReverseMarkPositioning,
  [DiagramTypes.Esp]: ESPPositioning,
  [DiagramTypes.PlungerLift]: PlungerLiftPositioning,
  [DiagramTypes.Injection]: InjectionPositioning,
  [DiagramTypes.GasLift]: GasLiftPositioning,
  [DiagramTypes.Efm]: EFMPositioning,
  [DiagramTypes.GasLiftManager]: GasLiftManagerPositioning,
  [DiagramTypes.GasFlowMonitor]: GasFlowMonitorPositioning,
  [DiagramTypes.MultiWellPlungerLift]: MultiWellPlungerLiftPositioning,
  [DiagramTypes.TotalFlowTank]: TotalFlowTankPositioning,
  [DiagramTypes.LiquidFlowMeter]: LiquidFlowMeterPositioning,
  [DiagramTypes.PulseAccumulator]: PulseAccumulatorPositioning,
  [DiagramTypes.RegisterView]: RegisterViewPositioning,
  [DiagramTypes.NOVCoreLiftController]: NOVCoreLiftPositioning,
  [DiagramTypes.HydraulicStrokingUnit]: HydraulicStrokingUnitPositioning,
  [DiagramTypes.UnicoLRP]: UnicoLRPPositioning,
  [DiagramTypes.ChemicalInjectionPump]: ChemicalInjectionPumpPositioning,
  [DiagramTypes.ChemicalInjectionPumpDigiUltra]: ChemicalInjectionPumpDigiUltraPositioning,
  [DiagramTypes.PlungerAssistedGasLift]: [],
  [DiagramTypes.Facility]: FacilityPositioning,
  [DiagramTypes.ValveControl]: ValveControlPositioning,
  [DiagramTypes.PID]: PIDPositioning,
  [DiagramTypes.TundraSSi]: TundraSSIPositioning,
  [DiagramTypes.Rotaflex]: RotaflexPositioning,
  [DiagramTypes.JetPump]: JetPumpPositioning,
};
