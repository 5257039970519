import { CardCoordinate } from '../models/Card';

export const findIntersects = (curveA: CardCoordinate[], curveB: CardCoordinate[]) => {
  if (!curveA || !curveB) {
    throw new Error('Both curveA and curveB must be provided.');
  }

  // Step along both curves, by segment, at the same time.
  // If segments could intersect, try to calculate the intersection. If not, step further along the curve.
  const intersections: CardCoordinate[] = [];
  const curveASorted: CardCoordinate[] = [...curveA]
    .sort((coord1, coord2) => coord1.x - coord2.x)
    .filter(
      (c) =>
        c.x >= Number.MIN_SAFE_INTEGER &&
        c.x <= Number.MAX_SAFE_INTEGER &&
        c.y >= Number.MIN_SAFE_INTEGER &&
        c.y <= Number.MAX_SAFE_INTEGER,
    )
    .map((x) => ({ x: x.x, y: x.y }));
  const curveBSorted: CardCoordinate[] = [...curveB]
    .sort((coord1, coord2) => coord1.x - coord2.x)
    .filter(
      (c) =>
        c.x >= Number.MIN_SAFE_INTEGER &&
        c.x <= Number.MAX_SAFE_INTEGER &&
        c.y >= Number.MIN_SAFE_INTEGER &&
        c.y <= Number.MAX_SAFE_INTEGER,
    )
    .map((x) => ({ x: x.x, y: x.y }));

  let iA = 0;
  let iB = 0;

  while (iA < curveASorted.length - 1 && iB < curveBSorted.length - 1) {
    if (segmentPairMayIntersect(curveASorted[iA], curveASorted[iA + 1], curveBSorted[iB], curveBSorted[iB + 1])) {
      const intersection = calculateIntersectionOfSegments(
        curveASorted[iA],
        curveASorted[iA + 1],
        curveBSorted[iB],
        curveBSorted[iB + 1],
      );

      if (intersection) {
        intersections.push(intersection);
      }
    }

    // Move to the next segment
    if (curveASorted[iA + 1].x < curveBSorted[iB + 1].x) {
      iA++;
    } else {
      iB++;
    }
  }

  return intersections;
};

const segmentPairMayIntersect = (
  firstSegmentStartPoint: CardCoordinate,
  firstSegmentEndPoint: CardCoordinate,
  secondSegmentStartPoint: CardCoordinate,
  secondSegmentEndPoint: CardCoordinate,
) => {
  // Check if segment pairs can potentially intersect.
  // If one pair is completely above or below the other, they will not intersect.
  return !(
    (firstSegmentStartPoint.y > secondSegmentStartPoint.y &&
      firstSegmentStartPoint.y > secondSegmentEndPoint.y &&
      firstSegmentEndPoint.y > secondSegmentStartPoint.y &&
      firstSegmentEndPoint.y > secondSegmentEndPoint.y) ||
    (firstSegmentStartPoint.y < secondSegmentStartPoint.y &&
      firstSegmentStartPoint.y < secondSegmentEndPoint.y &&
      firstSegmentEndPoint.y < secondSegmentStartPoint.y &&
      firstSegmentEndPoint.y < secondSegmentEndPoint.y)
  );
};

const calculateIntersectionOfSegments = (
  pointA1: CardCoordinate,
  pointA2: CardCoordinate,
  pointB1: CardCoordinate,
  pointB2: CardCoordinate,
) => {
  const slopeA = (pointA2.y - pointA1.y) / (pointA2.x - pointA1.x);
  const slopeB = (pointB2.y - pointB1.y) / (pointB2.x - pointB1.x);

  const cA = pointA2.y - slopeA * pointA2.x;
  const cB = pointB2.y - slopeB * pointB2.x;

  let xIntersect: number;
  let yIntersect: number;

  if (slopeA !== slopeB) {
    xIntersect = (cB - cA) / (slopeA - slopeB);
    yIntersect = slopeA * xIntersect + cA;
  } else {
    // Parallel segments, no intersection
    return null;
  }

  const xIntersectOnSegmentAAndB =
    isBetween(xIntersect, pointA1.x, pointA2.x) && isBetween(xIntersect, pointB1.x, pointB2.x);
  const yIntersectOnSegmentAAndB =
    isBetween(yIntersect, pointA1.y, pointA2.y) && isBetween(yIntersect, pointB1.y, pointB2.y);

  if (xIntersectOnSegmentAAndB && yIntersectOnSegmentAAndB) {
    return { x: xIntersect, y: yIntersect } as CardCoordinate;
  }

  return null;
};

const isBetween = (value: number, valueA: number, valueB: number, inclusive = true) => {
  const tolerance = 0.0001;

  if (inclusive) {
    const equalWithinToleranceA = Math.abs(value - valueA) < tolerance;
    const equalWithinToleranceB = Math.abs(value - valueB) < tolerance;

    return (
      ((valueA < value || equalWithinToleranceA) && (value < valueB || equalWithinToleranceB)) ||
      ((valueB < value || equalWithinToleranceB) && (value < valueA || equalWithinToleranceA))
    );
  } else {
    return (valueA < value && value < valueB) || (valueB < value && value < valueA);
  }
};
