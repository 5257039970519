import { FC, useEffect, useRef, useState } from 'react';
import React from 'react';
import { IImageOverlayModel } from '../../models/ImageOverlayModel';
import { IOverlayDataValueModel } from '../../models/DataValueModel';
import { OverLayFields } from '../asset-status/models/OverlayFields';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/storeHooks';
import { DisplayStatusStateCssClassMap } from '../asset-status/DisplayStatusStateCssClassMap';
import RefreshIcon from '../asset-status/icons/RefreshIcon';
import { DiagramTypes } from '../asset-status/models/DiagramTypes';
import Loader from '../common/Loader/Loader';
import { GetDataOverlayCssClass } from './Utils';
import './AssetImageOverlay.css';

interface Props {
  bkgndImgUrl: string;
  data: IImageOverlayModel[];
  diagramType: number;
  containerRef: React.RefObject<HTMLDivElement>;
  onRefresh: () => void;
}

export const AssetImageOverlay: FC<Props> = ({ bkgndImgUrl, data, diagramType, containerRef, onRefresh }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [hasCompletedFirstTimeScale, setHasCompletedFirstTimeScale] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const overlayLayerRef = useRef<HTMLDivElement>(null);
  const isLoading = useAppSelector((state) => state.rodliftwellstatus.loading);
  const [scaleFactor, setScaleFactor] = useState(1);

  const { assetId } = useParams<{ assetId: string }>();

  const resizeObserver = new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      ScaleImageToFit();
    });
  });

  useEffect(() => {
    setImageLoaded(false);
    setHasCompletedFirstTimeScale(false);
  }, [assetId]);

  useEffect(() => {
    if (imageLoaded && !hasCompletedFirstTimeScale && data) {
      ScaleImageToFit();
      setHasCompletedFirstTimeScale(true);
    }
  }, [imageLoaded, hasCompletedFirstTimeScale, data]);

  useEffect(() => {
    if (!containerRef.current) return;

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  function ScaleImageToFit() {
    if (!imageRef.current || !overlayLayerRef.current) return;

    const padding = 14;

    const scaleFactor = imageRef.current.clientWidth / imageRef.current.naturalWidth;
    setScaleFactor(scaleFactor);

    overlayLayerRef.current.style.transform = `scale(${scaleFactor})`;
    overlayLayerRef.current.style.transformOrigin = 'top left';
    overlayLayerRef.current.style.width = `${imageRef.current.naturalWidth}px`;
    overlayLayerRef.current.style.height = `${imageRef.current.naturalHeight}px`;

    overlayLayerRef.current.style.position = 'absolute';
    overlayLayerRef.current.style.top = `${padding}px`;
    overlayLayerRef.current.style.left = `${padding}px`;
  }

  const handleImageLoaded = () => setImageLoaded(true);

  const handleRefresh = () => {
    onRefresh();
    setHasCompletedFirstTimeScale(false);
    setImageLoaded(false);
  };

  const shouldUseLargeRunStatus =
    diagramType !== DiagramTypes.TundraSSi &&
    diagramType !== DiagramTypes.NOVCoreLiftController &&
    diagramType !== DiagramTypes.LowProfile &&
    diagramType !== DiagramTypes.UnicoLRP &&
    diagramType !== DiagramTypes.HydraulicStrokingUnit;

  return (
    <div
      ref={containerRef}
      className='flex card asset-status-card flex-column'
      style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}
    >
      <div ref={headerRef} className='w-full header'>
        <div className='flex flex-row justify-between w-full'>
          <span className='title'>Well status</span>
          <div className='asset-status-refresh-icon' onClick={handleRefresh}>
            <RefreshIcon />
          </div>
        </div>
      </div>

      <div
        className='flex image-view-component body p-relative'
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'flex-start',
        }}
      >
        {isLoading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
            }}
          >
            <Loader />
          </div>
        )}
        {!isLoading && (
          <img
            style={{ marginTop: 60 * scaleFactor }}
            ref={imageRef}
            src={bkgndImgUrl}
            alt='Well Diagram'
            onLoad={handleImageLoaded}
          />
        )}
        {!isLoading && imageLoaded ? (
          <div
            ref={overlayLayerRef}
            className='data-overlay-layer'
            style={{ width: '100%', height: '100%', marginTop: 60 * scaleFactor }}
          >
            {/* Plot the container for API Designation and Last Good Scan first. */}
            {data && (
              <div
                className='data-overlay api-designation'
                style={{
                  border: 'unset',
                  backgroundColor: 'unset',
                }}
              >
                {data
                  .filter(
                    (iom) =>
                      iom.dataValues.length > 0 &&
                      (iom.dataValues[0]?.overlayField === OverLayFields.ApiDesignation ||
                        iom.dataValues[0]?.overlayField === OverLayFields.LastGoodScan),
                  )
                  ?.map(
                    (datum) =>
                      datum.dataValues?.map((dv: IOverlayDataValueModel, index: number) => {
                        return (
                          <div
                            key={index}
                            className={GetDataOverlayCssClass(dv)}
                            title={`${dv.label}: ${dv.value}`}
                            style={{
                              borderRadius: '4px',
                            }}
                          >
                            <span className='label'>{dv.label}:</span>
                            <span className='value'>{dv.value ?? '——'}</span>
                          </div>
                        );
                      }),
                  )}
              </div>
            )}

            {/* Plot the general case */}
            {data?.map((iom: IImageOverlayModel, ndx: number) => {
              if (
                (iom.dataValues.length > 0 && iom.dataValues[0]?.overlayField === OverLayFields.ApiDesignation) ||
                iom.dataValues[0]?.overlayField === OverLayFields.LastGoodScan
              ) {
                return;
              }

              return (
                <div
                  key={ndx}
                  className={'data-overlay' + (iom.dataValues.length > 1 ? ' asset-status-data-overlay' : '')}
                  style={{
                    left: iom.justification === 'right' ? 'unset' : `${iom.position.x}px`,
                    right: iom.justification === 'right' ? `${iom.position.x}px` : 'unset',
                    top: `${iom.position.y}px`,
                    maxHeight: iom.maxHeight ?? 'unset',
                  }}
                >
                  {iom.title && (
                    <div
                      className='mb-1 data-overlay-title'
                      style={{
                        padding: iom.dataValues.length > 0 ? '0px' : '8px',
                      }}
                    >
                      {iom.title}:{' '}
                    </div>
                  )}
                  <div className='flex flex-col w-full gap-2'>
                    {iom.dataValues?.map((dv: IOverlayDataValueModel, index: number) => {
                      const showValue =
                        dv.overlayField !== OverLayFields.ControllerInformation &&
                        dv.overlayField !== OverLayFields.ControllerAndNode &&
                        dv.overlayField !== OverLayFields.IntermittenRealTime &&
                        dv.overlayField !== OverLayFields.FirstHead &&
                        dv.overlayField !== OverLayFields.SecondHead;

                      const shouldUseNormalDisplayState =
                        dv.overlayField === OverLayFields.CurrentCommunicationStatus ||
                        (dv.overlayField === OverLayFields.RunStatus && !shouldUseLargeRunStatus);

                      const breakLine = dv.overlayField === OverLayFields.ControllerAndNode;
                      const shouldWrap =
                        dv.overlayField === OverLayFields.CurrentLoadFactor &&
                        diagramType === DiagramTypes.MultiWellPlungerLift;

                      return (
                        <div
                          key={index}
                          className={
                            GetDataOverlayCssClass(dv, shouldUseNormalDisplayState) +
                            (iom.dataValues.length > 1 ? ' gap-3' : '')
                          }
                          title={`${dv.label}: ${dv.value}`}
                        >
                          {shouldUseNormalDisplayState && (
                            <div>
                              <div
                                className={`status-indicator ${DisplayStatusStateCssClassMap.get(dv.displayState)}`}
                              />
                            </div>
                          )}
                          <span className='label'>
                            {breakLine
                              ? dv.label.split('\n').map((word, index) => <div key={index}>{word}</div>)
                              : dv.label}
                            {showValue && dv.label != '' ? ':' : null}
                          </span>
                          {showValue && (
                            <span
                              style={{
                                whiteSpace: shouldWrap || iom.maxWidth ? 'normal' : 'nowrap',
                                maxWidth: iom.maxWidth ?? 'unset',
                              }}
                              className='value'
                            >
                              {dv.value && dv.value !== '' ? dv.value : '——'}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
