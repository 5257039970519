import React from 'react';
import './ESPChartLegend.scss';

interface LegendItem {
  id: number;
  name: string;
  color: string | undefined;
}

interface ESPChartLegendProps {
  legendItems: LegendItem[];
}

const ESPChartLegend: React.FC<ESPChartLegendProps> = ({ legendItems }) => {
  return (
    <div className='esp-chart-legend-container'>
      <div className='flex flex-col items-center justify-center w-full h-full mb-10'>
        <div className='esp-chart-legend-items'>
          {legendItems?.map((label) => (
            <div key={label.id} className='flex flex-row items-center justify-center'>
              <div
                className='esp-chart-legend-dash'
                style={{ backgroundColor: label.color ? label.color : '#006DFA' }}
              />
              <div className='esp-chart-legend-label'>{label.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ESPChartLegend;
