import React, { useEffect, useState } from 'react';
import { IImageOverlayModel } from '../../../models/ImageOverlayModel';
import { AssetImageOverlay } from '../../image-overlay/AssetImageOverlay';
import PropertyValueList from '../../property-value-list/PropertyValueList';
import { useAppSelector } from '../../../hooks/storeHooks';

interface ImageDiagramProps {
  backgroundImage: string;
  imageOverlays: IImageOverlayModel[];
  exceptionProperties: any[];
  alarmProperties: any[];
  wellTestProperties: any[];
  statusRegisterProperties: any[];
  wellStatusOverview: any[];
  diagramType: number;
  onRefresh: () => void;
}

const HEADER_HEIGHT = 50;

const ImageDiagram = ({
  backgroundImage,
  imageOverlays,
  exceptionProperties,
  alarmProperties,
  wellTestProperties,
  statusRegisterProperties,
  wellStatusOverview,
  diagramType,
  onRefresh,
}: ImageDiagramProps) => {
  const [statusRegistersHeight, setStatusRegistersHeight] = useState<number>(0);

  const selectedAssetId = useAppSelector((state) => state.assetGroups.selectedAssetId);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const resizeObserver = new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      setStatusRegistersHeight(Math.floor(entries[0].contentRect.height));
    });
  });

  useEffect(() => {
    if (!containerRef.current) return;

    resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  return (
    <div className='flex flex-row gap-3 w-full'>
      <div className='flex flex-column flex-1'>
        <AssetImageOverlay
          bkgndImgUrl={backgroundImage}
          data={imageOverlays}
          diagramType={diagramType}
          containerRef={containerRef}
          onRefresh={onRefresh}
        />
      </div>
      <div className='flex flex-row flex-1 gap-3'>
        <div className='flex flex-column flex-1 gap-3'>
          <PropertyValueList description={selectedAssetId ?? ''} properties={wellStatusOverview} showLength={false} />
          <PropertyValueList description='Exceptions' properties={exceptionProperties} showLength={false} />
          <PropertyValueList description='Alarms' properties={alarmProperties} showLength={true} maxHeight='191px' />
          <PropertyValueList description='Last well test' properties={wellTestProperties} showLength={false} />
        </div>
        <div className='flex flex-column flex-1'>
          <PropertyValueList
            description='Status registers'
            properties={statusRegisterProperties}
            showLength={false}
            maxHeight={`${statusRegistersHeight - HEADER_HEIGHT}px`}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageDiagram;
