import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const NOVCoreLiftPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 213, y: 116 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 213, y: 153 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 213, y: 190 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RunStatus,
    position: { x: 289, y: 342 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 289, y: 370 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 575, y: 392 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FlowlinePressure,
    position: { x: 575, y: 429.13 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 575, y: 466.27 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpDepth,
    position: { x: 575, y: 545 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpType,
    position: { x: 575, y: 582.13 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RodLoading,
    position: { x: 575, y: 619.27 },
    justification: 'left',
  },
];
