import { PositioningType } from '../models/PositioningType';
import { OverLayFields } from '../models/OverlayFields';

export const ChemicalInjectionPumpPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 533, y: 70 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RTUAddress,
    position: { x: 533, y: 107.94 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.HostName,
    position: { x: 533, y: 146 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Protocol,
    position: { x: 533, y: 184 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ChemicalInjectionInformation,
    position: { x: 3, y: 465 },
    justification: 'left',
  },
];
