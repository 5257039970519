import api from '../../services/api';

// Fetch Card Dates
export interface FetchCardDatesResponseValues {
  date: string;
  cardTypeId: string;
  cardTypeName: string;
  id: number;
}

export interface FetchCardDatesResponse {
  values: FetchCardDatesResponseValues[];
}

const fetchCardDatesAsync = async (assetId: string) => {
  try {
    return await api
      .get('Cards?assetId=' + assetId, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data as FetchCardDatesResponse;
        }
      });
  } catch (error) {
    console.error('Error fetching rod lift analysis card dates', error);
  }
};

// Fetch Card Coordinates
export interface FetchCardCoordinatesCoordinate {
  x: number;
  y: number;
}

export interface FetchCardCoordinatesObject {
  id: number;
  name: string;
  coordinates: FetchCardCoordinatesCoordinate[];
}

export interface FetchCardCoordinatesResponse {
  values: FetchCardCoordinatesObject[];
}

const fetchCardCoordinatesAsync = async (assetId: string, cardDate: string) => {
  const queryParams = {
    assetId,
    cardDate,
  };

  return await api
    .get('Analytics/RLAnalysis/RLCoordinates', {
      params: queryParams,
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(function (response: any) {
      if (response) {
        return response.data as FetchCardCoordinatesResponse;
      }
    });
};

// Fetch Analysis Results
export interface AnalysisInputOutputResponseValues {
  id: string;
  name: string;
  displayName: string;
  value: string;
  displayValue: string;
  dataTypeId: number;
  measurementAbbreviation: string;
  sourceId: number;
}

export interface FetchAnalysisResponseValues {
  input: AnalysisInputOutputResponseValues[];
  output: AnalysisInputOutputResponseValues[];
}

export interface FetchAnalysisResponse {
  values: FetchAnalysisResponseValues;
}

const fetchAnalysisAsync = async (assetId: string, cardDate: string) => {
  try {
    const queryParams = {
      assetId,
      cardDate,
    };

    return await api
      .get('Analytics/RLAnalysis', {
        params: queryParams,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data as FetchAnalysisResponse;
        }
      });
  } catch (error) {
    console.error('Error fetching rod lift analysis', error);
  }
};

const fetchCardDetailsInitialStateAsync = async () => {
  try {
    return await api
      .get('CardData', {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data as FetchAnalysisResponse;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export { fetchCardDatesAsync, fetchCardCoordinatesAsync, fetchAnalysisAsync, fetchCardDetailsInitialStateAsync };
