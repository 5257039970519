import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const PulseAccumulatorPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 73, y: 193 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 73, y: 217 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FlowRate,
    position: { x: 73, y: 255 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ProductionYesterdayVolume,
    position: { x: 73, y: 293 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.AccumulatedVolume,
    position: { x: 73, y: 331 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PulseCount,
    position: { x: 73, y: 369 },
    justification: 'left',
  },
];
