import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { CurveType } from '../../../enums/CurveType';

export const CreateTemeratureSeries = (props) => {
  const tooltip = am5core.Tooltip.new(props[0].root, {
    labelText: '{name}\nX: {valueX}\nY: {valueY}',
    getFillFromSprite: false,
    getLabelFillFromSprite: false,
    autoTextColor: false,
  });

  const series = props[0].chart.series.push(
    am5xy.LineSeries.new(props[0].root, {
      name: props[0].seriesName,
      xAxis: props[0].temperatureXAxis,
      yAxis: props[0].yAxis,
      valueYField: 'y',
      valueXField: 'x',
      tooltip: tooltip,
      connect: false,
      stroke: props[0].color
        ? am5core.color(`rgb(${props[0].color.r}, ${props[0].color.g}, ${props[0].color.b})`)
        : am5core.color('#FF0000'),
    }),
  );

  const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;
  tooltip.get('background')?.set('fill', series.get('stroke'));
  tooltip.label.setAll({
    fill:
      seriesColor.r * 0.299 + seriesColor.g * 0.587 + seriesColor.b * 0.114 > 150
        ? am5core.color('#000')
        : am5core.color('#fff'),
    fontSize: 12,
    fontWeight: '400',
  });

  if (props[0].curveTypeId === CurveType.TemperatureSurveyCurve) {
    series.strokes.template.set('strokeDasharray', [1, 1]);
  }

  series.data.setAll(props[0].coordinates);
};
