import React, { useEffect } from 'react';
import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Card, CardCoordinate, RGBColor, SelectedCardTrend } from '../../../../models/Card';
import './PressureProfileChart.scss';
import { CurveType } from '../../enums/CurveType';
import PressureProfileChartColors from './presureProfileChartColors';
import {
  CreateYAxis,
  CreateXAxis,
  CreateTemperatureXAxis,
  CreateGasRateXAxis,
  CreateValvePointSeries,
  CreateTemeratureSeries,
} from './components';
import { createTooltipDefault, setTooltipDefault, AxisDefault } from '../../../../utilities/ChartDefaults';
import { useAppSelector } from '../../../../hooks/storeHooks';

interface PressureProfileChartProps {
  selectedCardTrends?: SelectedCardTrend[];
  cards?: Card[];
  loadDepthCoordinates(cardCoordinates: CardCoordinate[]);
}

const PressureProfileChart: React.FC<PressureProfileChartProps> = (props: PressureProfileChartProps) => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const isDarkMode = themeMode === 'dark';

  useEffect(() => {
    const root = am5core.Root.new('pressure-profile-chart');

    // create chart.
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelY: 'zoomXY',
        maxTooltipDistance: -1,
        width: am5core.percent(100),
      }),
    );

    const axisProps = [
      {
        chart: chart,
        root: root,
        minX: AxisDefault.Min,
        minY: AxisDefault.Max,
        color: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      },
    ];

    // create y axis.
    const yAxis = CreateYAxis(axisProps);
    const yAxisRenderer = yAxis.get('renderer');
    yAxisRenderer.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

    // create x axis.
    const xAxis = CreateXAxis(axisProps);
    const xAxisRenderer = xAxis.get('renderer');
    xAxisRenderer.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

    // create temperature axis and border .
    const xAxisWidth = xAxis.width();
    const temperatureAxisWidth = xAxisWidth * 0.4;
    const startPosition = xAxisWidth - temperatureAxisWidth;
    const tempeartureProps = [
      {
        chart: chart,
        root: root,
        temperatureAxisWidth: temperatureAxisWidth,
        startPosition: startPosition,
        color: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      },
    ];
    const temperatureXAxis = CreateTemperatureXAxis(tempeartureProps);
    const temperatureAxisRenderer = temperatureXAxis.get('renderer');
    temperatureAxisRenderer.labels.template.set(
      'fill',
      isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
    );

    // create gas rate axis and border.
    const gasRateAxisProps = [
      {
        chart: chart,
        root: root,
        gasRateMinX: AxisDefault.Min,
        gasRateMaxX: AxisDefault.Max,
        color: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      },
    ];
    const gasRateXAxis = CreateGasRateXAxis(gasRateAxisProps);
    const gasRateRenderer = gasRateXAxis.get('renderer');
    gasRateRenderer.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

    // set axes min and max on bounds changed event.
    chart.events.on('boundschanged', (event) => {
      updateAxesWidths(event);
    });

    // calculate min and max for all axes.
    calculateMinMaxForAxes();

    // plot all series.
    props.selectedCardTrends?.forEach((selectedCardTrend) => {
      props.cards?.forEach((card) => {
        card.cardTrends?.forEach((cardTrend) => {
          if (
            cardTrend.curveTypeId == selectedCardTrend.id &&
            cardTrend.displayName?.toLowerCase() === selectedCardTrend.label?.toLowerCase()
          ) {
            if (
              selectedCardTrend.id === CurveType.Temperature ||
              selectedCardTrend.id === CurveType.TemperatureSurveyCurve
            ) {
              CreateTemeratureSeries([
                {
                  chart: chart,
                  root: root,
                  seriesName: cardTrend.displayName,
                  color: card.color,
                  coordinates: cardTrend.coordinates,
                  temperatureXAxis: temperatureXAxis,
                  yAxis: yAxis,
                  curveTypeId: selectedCardTrend.id,
                },
              ]);
            } else {
              createSeries(cardTrend.displayName, card.color, cardTrend.coordinates, cardTrend.curveTypeId);
              if (selectedCardTrend.id === CurveType.InjectionRateForCriticalVelocity) {
                const valvePoints = props.loadDepthCoordinates(cardTrend.coordinates);
                CreateValvePointSeries([
                  {
                    chart: chart,
                    root: root,
                    seriesName: cardTrend.displayName,
                    coordinates: valvePoints,
                    xAxis: gasRateXAxis,
                    yAxis: yAxis,
                  },
                ]);
              }
            }
          }
        });
      });
    });

    root.setThemes([am5themes_Animated.new(root)]);

    // create cursor.
    const customCursor = am5xy.XYCursor.new(root, {});
    chart.set('cursor', customCursor);

    return () => {
      root.dispose();
    };

    // function definitions.
    function updateAxesWidths(event) {
      const positionOffset = 23;
      const widthOffset = 17;
      temperatureXAxis.set('x', event.target.width() - event.target.width() * 0.4 - positionOffset);
      temperatureXAxis.set('maxWidth', event.target.width() * 0.4 - widthOffset);
      temperatureXAxis.set('width', event.target.width() * 0.4 - widthOffset);
    }

    function calculateMinMaxForAxes() {
      const allXValuesTemperature: number[] = [];
      const allXValues: number[] = [];
      const allYValues: number[] = [];
      const allXValuesGasRate: number[] = [];

      props.selectedCardTrends?.forEach((selectedCardTrend) => {
        props.cards?.forEach((card) => {
          card.cardTrends?.forEach((cardTrend) => {
            if (cardTrend.coordinates.length == 0 || cardTrend.curveTypeId != selectedCardTrend.id) {
              return;
            }

            // calculate min and max for temperature axis.
            if (
              selectedCardTrend.id == CurveType.Temperature ||
              selectedCardTrend.id == CurveType.TemperatureSurveyCurve
            ) {
              allXValuesTemperature.push(...cardTrend.coordinates.map((coord) => coord.x));
            } else if (
              selectedCardTrend.id == CurveType.PressureSurveyCurve ||
              selectedCardTrend.id == CurveType.GasInjectionPressure ||
              selectedCardTrend.id == CurveType.ProductionFluidPressure ||
              selectedCardTrend.id == CurveType.ReservoirFluidHydrostatic ||
              selectedCardTrend.id == CurveType.KillFluidHydrostatic
            ) {
              allXValues.push(...cardTrend.coordinates.map((coord) => coord.x));
            } else if (selectedCardTrend.id == CurveType.InjectionRateForCriticalVelocity) {
              allXValuesGasRate.push(...cardTrend.coordinates.map((coord) => coord.x));
            }

            // accumulate all Y values for Y axis
            allYValues.push(...cardTrend.coordinates.map((coord) => coord.y));
          });
        });
      });

      // update axis bounds
      temperatureXAxis.set(
        'min',
        allXValuesTemperature.length > 0 ? Math.min(...allXValuesTemperature) * 0.5 : AxisDefault.Min,
      );
      temperatureXAxis.set(
        'max',
        allXValuesTemperature.length > 0 ? Math.max(...allXValuesTemperature) * 1.2 : AxisDefault.Max,
      );
      xAxis.set('max', allXValues.length > 0 ? Math.max(...allXValues) * 1.2 : AxisDefault.Max);
      gasRateXAxis.set('max', allXValuesGasRate.length > 0 ? Math.max(...allXValuesGasRate) * 1.1 : AxisDefault.Max);
      yAxis.set('max', allYValues.length > 0 ? Math.max(...allYValues) * 0.99 : AxisDefault.Max);
    }

    function createSeries(
      seriesName: string,
      color: RGBColor | undefined,
      coordinates: CardCoordinate[],
      curveTypeId: number,
    ) {
      const tooltip = createTooltipDefault(root);

      let seriesXAxis = xAxis;

      if (curveTypeId == CurveType.InjectionRateForCriticalVelocity) {
        seriesXAxis = gasRateXAxis;
      }

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: seriesXAxis,
          yAxis: yAxis,
          valueYField: 'y',
          valueXField: 'x',
          tooltip: tooltip,
          connect: false,
          stroke: color
            ? am5core.color(`rgb(${color.r}, ${color.g}, ${color.b})`)
            : am5core.color(PressureProfileChartColors.find((color) => color.id === curveTypeId)?.color || '#CD5C5C'),
        }),
      );

      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;
      setTooltipDefault(tooltip, seriesColor);

      if (curveTypeId == CurveType.PressureSurveyCurve) {
        series.strokes.template.set('strokeDasharray', [1, 1]);
      }

      series.data.setAll(coordinates);
    }
  }, [props.cards?.length, props.selectedCardTrends?.length, isDarkMode]);

  return <div id='pressure-profile-chart' className='pressure-profile-chart'></div>;
};

export default PressureProfileChart;
