import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const LowProfilePositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 7, y: 66.6 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 7, y: 108.75 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 649, y: 98.37 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 434, y: 436.5 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 434, y: 474.41 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpDepth,
    position: { x: 434, y: 511.42 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpType,
    position: { x: 434, y: 549.42 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 434, y: 601 },
    justification: 'left',
  },
];
