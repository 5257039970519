interface LocalStorageConstant {
  accessToken: string;
  refreshToken: string;
  user: string;
  userAddresses: string;
  rememberedCredentials: string;
  expiresIn: string;
  correlationId: string;
}

const LocalStorageConstant: LocalStorageConstant = {
  accessToken: `${process.env.REACT_APP_AUTH_STORAGE_KEY}access-token`,
  refreshToken: `${process.env.REACT_APP_AUTH_STORAGE_KEY}refresh-token`,
  user: `${process.env.REACT_APP_AUTH_STORAGE_KEY}user`,
  userAddresses: `${process.env.REACT_APP_AUTH_STORAGE_KEY}user-addresses`,
  rememberedCredentials: `${process.env.REACT_APP_AUTH_STORAGE_KEY}rememberedCredentials`,
  expiresIn: `${process.env.REACT_APP_AUTH_STORAGE_KEY}expiresIn`,
  correlationId: `${process.env.REACT_APP_AUTH_STORAGE_KEY}correlationId`,
};

export default LocalStorageConstant;
