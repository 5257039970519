import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks';
import { fetchAnalyticsGLAnalysisAsync, setInitialState as setAnalysisInitialState } from './GLAnalysisSlice';
import {
  fetchGlAnalysisCurveCoordinatesInitialStateAsync,
  setInitialState as setCurveCoordinatesInitialState,
} from './GLAnalysisCurveCoordinatesSlice';
import { WellTestData, fetchWellTestInitialStateAsync, toggleSelected } from './GLAnalysisWellTestSlice';
import {
  fetchSurveyCurveCoordinatesInitialStateAsync,
  setInitialState as setSurveyCoordinatesInitialState,
} from './GLAnalysisSurveyCurveCoordinatesSlice';
import CardDateItem from '../rod-lift-analysis/CardDateItem/CardDateItem';
import { useParams } from 'react-router-dom';
import { SurveyDataData, fetchSurveyDateInitialStateAsync, toggleSurveySelected } from './GLAnalysisSurveyDateSlice';
import { FixedSizeList as List } from 'react-window';

interface GLAnalysisProps {
  tabId: number;
  selectedWellTestType: string[];
  dateRangeFilteredCardDates: WellTestData[] | SurveyDataData[] | null;
}

interface UrlParams {
  assetId: string;
}

const GlAnalysisWellTest = (props: GLAnalysisProps) => {
  const { tabId, selectedWellTestType } = { ...props };
  const dispatch = useAppDispatch();
  const { assetId } = useParams<keyof UrlParams>() as UrlParams;

  const wellTestData = useAppSelector((state) => state.glAnalysisWellTest.glAnalysisWellTests);
  const surveyDateData = useAppSelector((state) => state.gLAnalysisSurveyDate.glAnalysisSurveyDates);

  const fetchGlAnalysisAsync = fetchAnalyticsGLAnalysisAsync;
  const fetchSurveyCurveCoordinatesAsync = fetchSurveyCurveCoordinatesInitialStateAsync;
  const fetchSurveyDateStateAsync = fetchSurveyDateInitialStateAsync;

  useEffect(() => {
    dispatch(setAnalysisInitialState());
    dispatch(setCurveCoordinatesInitialState());
    dispatch(setSurveyCoordinatesInitialState());

    const initializeWellTestStore = async () => {
      dispatch(fetchWellTestInitialStateAsync({ assetId: assetId, type: 'gl' })).then((response) => {
        if (response.payload?.values.length > 0) {
          const firstCardDate = response.payload.values[0].date;
          const firstCardAnalysisResultId = response.payload.values[0].analysisResultId;
          const firstCardAnalysisTypeId = response.payload.values[0].analysisTypeId;
          const firstFetch = true;
          onClickActionHandler(1, firstCardDate, firstCardAnalysisResultId, firstCardAnalysisTypeId, firstFetch, 0);
        }
      });
    };

    const initializeSurveyStore = async () => {
      dispatch(fetchSurveyDateStateAsync({ assetId: assetId }));
    };

    initializeWellTestStore();
    initializeSurveyStore();
  }, [assetId]);

  const onClickActionHandler = (
    id: number,
    date: string,
    analysisResultId: number,
    analysisTypeId: number,
    firstFetch?: boolean,
    tabIdArgument?: number,
  ) => {
    let tabToUse = tabId;

    if (tabIdArgument !== undefined) {
      tabToUse = tabIdArgument;
    }

    if (tabToUse === 0) {
      dispatch(toggleSelected(id));
    } else {
      dispatch(toggleSurveySelected(id));
    }

    let fetchData = true;
    if (firstFetch) {
      fetchData = true;
    } else if (
      (tabToUse === 0 && wellTestData?.find((x) => x.id == id)?.isSelected == true) ||
      (tabToUse === 1 && surveyDateData?.find((x) => x.id == id)?.isSelected == true)
    ) {
      // Don't fetch if toggling off card item.
      fetchData = false;
    }

    if (fetchData) {
      if (tabToUse == 0) {
        dispatch(
          fetchGlAnalysisCurveCoordinatesInitialStateAsync({
            assetId: assetId,
            testDate: date,
            analysisResultId: analysisResultId.toString(),
            analysisTypeId: analysisTypeId.toString(),
          }),
        );
        dispatch(
          fetchGlAnalysisAsync({
            assetId: assetId,
            testDate: date,
            analysisTypeId: analysisTypeId.toString(),
            analysisResultId: analysisResultId.toString(),
          }),
        );
      } else {
        dispatch(fetchSurveyCurveCoordinatesAsync({ assetId: assetId, surveyDate: date }));
      }
    }
    return;
  };

  const visibleWellTestItems = props.dateRangeFilteredCardDates ?? (tabId === 0 ? wellTestData : surveyDateData);

  const filteredWellTestItems = Array.isArray(visibleWellTestItems)
    ? visibleWellTestItems.filter(
        (wellTest) =>
          tabId !== 0 ||
          selectedWellTestType.length === 0 ||
          selectedWellTestType.some((label) => wellTest.analysisTypeName.toLowerCase().includes(label.toLowerCase())),
      )
    : [];

  const Row = ({ index, style }) => {
    const filteredWellTestItem = filteredWellTestItems[index];

    if (filteredWellTestItem == null) {
      return null;
    }

    return (
      <CardDateItem
        key={index}
        id={filteredWellTestItems[index].id}
        analysisTypeId={filteredWellTestItems[index].analysisTypeId}
        analysisResultId={filteredWellTestItems[index].analysisResultId}
        date={filteredWellTestItems[index].date}
        name={filteredWellTestItems[index].analysisTypeName}
        isSelected={filteredWellTestItems[index].isSelected}
        onClickAction={(id, date, analysisResultId, analysisTypeId) =>
          onClickActionHandler(id, date, analysisResultId, analysisTypeId)
        }
        showIcon={false}
        color={filteredWellTestItems[index].color}
        style={style}
      />
    );
  };

  return (
    <div>
      {Array.isArray(visibleWellTestItems) && visibleWellTestItems.length > 0 ? (
        <List height={385} itemCount={visibleWellTestItems.length} itemSize={55} width='100%'>
          {Row}
        </List>
      ) : null}
    </div>
  );
};

export default GlAnalysisWellTest;
