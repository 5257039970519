import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWellTestAsync } from './ESPAnalysisService';
import { getCardColor } from '../../utilities/CardColorGenerator';
import { RGBColor } from '../../models/Card';

interface WellTestItemState {
  id: number;
  date: string;
  analysisTypeName: string;
  isSelected: boolean;
  color: RGBColor | null;
}

interface WellTestListState {
  values: WellTestItemState[];
  loading: boolean;
  selectedCardDates: string[];
  selectedCardIds: number[];
}

const fetchESPAnalysisWellTestAsync = createAsyncThunk(
  'ESPAnalysis/fetchWellTest',
  async ({ assetId, type = 'esp' }: { assetId: string; type?: string }) => {
    const response = await fetchWellTestAsync(assetId, type);
    return response;
  },
);

const initialState: WellTestListState = {
  loading: false,
  values: [],
  selectedCardDates: [],
  selectedCardIds: [],
};

const espAnalysisCardDateSlice = createSlice({
  name: 'ESPAnalysis/WellTest',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    toggleSelected(state, action: PayloadAction<number>) {
      const id = action.payload;
      state.selectedCardDates = [];

      if (!state.selectedCardIds.includes(id) && state.selectedCardIds.length >= 50) {
        alert('You can add only 50 cards to the chart.');
        return;
      }

      state.values?.forEach((x, _, array) => {
        if (x.id == id) {
          x.isSelected = !x.isSelected;
        }
        if (x.isSelected) {
          state.selectedCardDates.push(x.date);
          x.color = getCardColor(array.findIndex((d) => d.id == x.id) + 1);
        }
      });
      if (state.selectedCardDates.length === 1) {
        state.values?.forEach((x) => {
          x.color = null;
        });
      }

      state.values?.forEach((x) => {
        if (x.id === id) {
          if (state.selectedCardIds.includes(id) && !x.isSelected) {
            state.selectedCardIds = state.selectedCardIds.filter((cardId) => cardId !== id);
          } else {
            if (!state.selectedCardIds.includes(id)) {
              state.selectedCardIds.push(id);
            }
          }
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchESPAnalysisWellTestAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchESPAnalysisWellTestAsync.fulfilled, (state, action) => {
      if (action?.payload?.values === undefined || action.payload.values.length === 0) {
        state.values = [];
        return;
      }

      let id = 1;

      state.values = action.payload?.values.map(
        (x) =>
          ({
            id: id++,
            date: x.date,
            analysisTypeName: x.analysisTypeName,
          }) as WellTestItemState,
      );
      state.loading = false;
    });
    builder.addCase(fetchESPAnalysisWellTestAsync.rejected, (state) => {
      state.loading = false;
      state.values = [];
    });
  },
});

export { fetchESPAnalysisWellTestAsync, WellTestItemState };
export const { toggleSelected, setInitialState } = espAnalysisCardDateSlice.actions;
export default espAnalysisCardDateSlice.reducer;
