import React, { useEffect } from 'react';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks/storeHooks';

import './PageLayout.css';

import NavigationBar from '../features/navigation/components/NavigationBar';
import '../styles/darktheme/style.scss';
import UserService from '../services/user/user.service';
import FooterBar from './FooterBar';

import './PageLayout.css';
import '../styles/darktheme/style.scss';
import { updateSelectedAsset } from './../features/navigation/AssetGroupsSlice';
import { fetchInitialStateAsync } from './../features/navigation/AssetGroupsSlice';
import { setIndustryApplicationId } from './../features/asset-analysis/AssetAnalysisSlice';
import { fetchNotificationsAsync } from './../features/navigation/NotificationsSlice';

export const Layout = () => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const assets = useAppSelector((state) => state.assetGroups?.assets);
  const selectedGroup = useAppSelector((state) => state.assetGroups?.selectedGroup);

  const { assetId, assetGroupName, groupName } = useParams();
  const groupHashMap = useAppSelector((state) => state.assetGroups.groupHashMap);

  useEffect(() => {
    if (location.pathname === '/') {
      UserService.getLandingPage().then((response) => {
        if (response) {
          const landingPage = JSON.parse(JSON.stringify(response));

          landingPage.PageType === 'Group'
            ? navigate('/group/status/' + landingPage.Value)
            : navigate('/asset/status/' + landingPage.Value.split('|')[1] + '/' + landingPage.Value.split('|')[0]);
        }
      });
    }
  }, []);

  useEffect(() => {
    const currentGroupName = assetGroupName ? assetGroupName : groupName ?? '';
    if (assets && (assetGroupName || groupName)) {
      const formattedGroupName = currentGroupName.split('_').join(' ');
      const mergedGroups = Object.values(groupHashMap);
      const selectedGroup = mergedGroups.find((group) => group.assetGroupName === formattedGroupName);
      const selectedAsset = selectedGroup?.assets.find((asset) => asset.assetId === assetId);
      if (selectedGroup || selectedAsset) {
        dispatch(
          updateSelectedAsset({
            groupId: Number(selectedGroup.id),
            assetId: selectedAsset?.assetName,
            searchString: undefined,
            assetIdGuid: undefined,
          }),
        );

        if (selectedAsset) {
          dispatch(
            fetchNotificationsAsync({
              type: 'asset',
              id: assetId || '',
              notificationTypeId: '',
            }),
          );
          dispatch(setIndustryApplicationId({ industryApplicationId: selectedAsset.industryApplicationId }));
        }
      }
    } else {
      if (currentGroupName) dispatch(fetchNotificationsAsync({ type: 'group', id: currentGroupName }));
      if (!assets) {
        dispatch(fetchInitialStateAsync());
      }
    }
  }, [selectedGroup, assets, dispatch, assetGroupName]);

  return (
    <div className={`outer-container ${themeMode === 'dark' ? 'theme-dark' : ''}`}>
      <div className='navbar-container'>
        <NavigationBar />
      </div>
      <div className='outlet-container'>
        <div className='flex h-full flex-column'>
          <Outlet />
        </div>
        <FooterBar />
      </div>
    </div>
  );
};
