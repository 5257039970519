import { useEffect } from 'react';

export const useClickOutside = (ref: React.RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node) && isClickWithinWindow(event)) {
        callback();
      }
    };

    const isClickWithinWindow = (event: MouseEvent) => {
      return event.target instanceof Node && document.documentElement.contains(event.target);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
