import React from 'react';
import Check from '../../../images/check.svg';
import './CheckBox.scss';

interface CheckboxProps {
  isChecked: boolean | undefined;
  Label: string | undefined;
  onItemSelect: () => void | undefined;
}

export const Checkbox = (props: CheckboxProps) => {
  const handleCheckboxChange = () => {
    props.onItemSelect();
  };

  return (
    <div className='checkbox-parent'>
      {props.isChecked ? (
        <label htmlFor='customCheckbox' className='checkbox checkbox-checked' onClick={handleCheckboxChange}>
          <img className='checkbox-check-mark' src={Check} alt='check' />
        </label>
      ) : (
        <label htmlFor='customCheckbox' className='checkbox' onClick={handleCheckboxChange}></label>
      )}
      <div className='checkbox-label'>{props.Label}</div>
    </div>
  );
};
