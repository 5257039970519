import React from 'react';
import { ESPPressureProfile as ESPPressureProfileModel } from '../ESPAnalysisService';
import './ESPPressureProfile.scss';
import { useAppSelector } from '../../../hooks/storeHooks';

interface ESPPressureProfileProps {
  pressureProfile?: ESPPressureProfileModel;
}

const ESPPressureProfile = ({ pressureProfile }: ESPPressureProfileProps) => {
  const isDarkMode = useAppSelector((store) => store.theme.mode) === 'dark';
  const strokeColor = isDarkMode ? 'white' : 'black';

  const renderPressureProfileLegendItem = (
    label: string,
    value: number | string | undefined,
    unit: string | undefined,
    fallbackUnit: string,
    color: string,
  ) => {
    return (
      <div className='esp-pressure-profile-legend-item-container'>
        <div className='esp-pressure-profile-legend-item-label-container'>
          <div className='esp-pressure-profile-legend-item-label' style={{ color: color, fontWeight: 'bold' }}>
            {label + ' '}
          </div>
        </div>
        <div className='esp-pressure-profile-legend-item-value-container'>
          <div className='esp-pressure-profile-legend-item-value' style={{ color: color }}>
            {value}
            {' ('}
            {unit ?? fallbackUnit}
            {')'}
          </div>
        </div>
      </div>
    );
  };

  const renderWellGraphic = (pressureProfile: ESPPressureProfileModel) => {
    const PRESSURE_RANGE = 120;

    // Calculate pump depth scale.
    let pumpDepthScale = 0.5;

    const verticalPumpDepth = pressureProfile.verticalPumpDepth?.value;
    const fluidLevel = pressureProfile.fluidLevel?.value;
    const perforationDepth = pressureProfile.perforationDepth?.value;

    if (
      verticalPumpDepth !== undefined &&
      fluidLevel !== undefined &&
      perforationDepth !== undefined &&
      perforationDepth !== fluidLevel
    ) {
      pumpDepthScale = (verticalPumpDepth - fluidLevel) / (perforationDepth - fluidLevel);
    }

    // Calculate fbhp estimation
    let fbhp: number | undefined = undefined;

    const pumpIntakePressure = pressureProfile.pumpIntakePressure?.value;
    const casingPressure = pressureProfile.casingPressure?.value;

    if (pumpIntakePressure !== undefined && casingPressure !== undefined) {
      fbhp = (pumpIntakePressure - casingPressure) / pumpDepthScale + casingPressure;
    }

    // Calculate fbhp scale.
    let fbhpScale = 0.5;

    const pumpDischargePressure = pressureProfile.pumpDischargePressure?.value;

    if (
      pumpDischargePressure !== undefined &&
      casingPressure !== undefined &&
      fbhp !== undefined &&
      pumpDischargePressure !== casingPressure
    ) {
      fbhpScale = (fbhp - casingPressure) / (pumpDischargePressure - casingPressure);
    }

    const fbhpTranslationFactor = fbhpScale * PRESSURE_RANGE;

    // Calculate pip scale
    let pipScale = 0.5;

    if (
      pumpDischargePressure !== undefined &&
      casingPressure !== undefined &&
      pumpIntakePressure !== undefined &&
      pumpDischargePressure !== casingPressure
    ) {
      pipScale = (pumpIntakePressure - casingPressure) / (pumpDischargePressure - casingPressure);
    }

    const pipTranslationFactor = pipScale * PRESSURE_RANGE;

    return (
      <>
        <div className='esp-pressure-profile-wellbore-graphic-container'>
          <div className='esp-pressure-profile-flow-direction-svg-container'>
            <svg viewBox='40.167212 55.083281 35.582786560058594 15.166717529296875' width='20px'>
              <path
                id='FlowDirection'
                d='M75.75,65.5 L58.25,65.5 58.25,70.25 40.167212,62.583303 58,55.083281 58.083333,59.75 75.75,59.75 z'
                fill={strokeColor}
              ></path>
            </svg>
          </div>
          <div className='esp-pressure-profile-head-out-1-svg-container'>
            <svg viewBox='78.21 67.54 24.3 2' width='35px' height='2px' preserveAspectRatio='none'>
              <line x1='79.208333' y1='68.541667' x2='101.50663' y2='68.541667' stroke={strokeColor} strokeWidth='2' />
            </svg>
          </div>
          <div className='esp-pressure-profile-head-out-2-svg-container'>
            <svg viewBox='78.21 67.54 24.3 2' width='23px' height='2px' preserveAspectRatio='none'>
              <line x1='79.208333' y1='68.541667' x2='101.50663' y2='68.541667' stroke={strokeColor} strokeWidth='2' />
            </svg>
          </div>
          <div className='esp-pressure-profile-well-head-image-container'>
            <div className='esp-pressure-profile-well-head-image' />
          </div>
          <div className='esp-pressure-profile-perforations-container'>
            <div className='esp-pressure-profile-perforation-depth-value'>
              {pressureProfile?.perforationDepth?.value}
              {' (' + pressureProfile?.perforationDepth?.unit + ')'}
            </div>
            <div className='esp-pressure-profile-perforations-image' />
            <div className='esp-pressure-profile-perforation-depth-label'>
              <div>Perf.</div>
              <div>Depth</div>
            </div>
          </div>
          <div className='esp-pressure-profile-casing-image-container'>
            <div className='esp-pressure-profile-casing-image' />
          </div>
          <div className='fluid-level-to-fbhp-line-container' style={{ width: `${7.5 + fbhpTranslationFactor}px` }}>
            <svg width='100%' height='100%' style={{ overflow: 'visible' }}>
              <line x1='0' y1='0' x2='100%' y2='100%' stroke='#52dc6b' strokeWidth='1.5' />
            </svg>
          </div>
          <div className='esp-pressure-profile-dynamic-level-line-svg-container'>
            <svg viewBox='78.21 67.54 24.3 2' width='122px' height='2px' preserveAspectRatio='none'>
              <line x1='79.208333' y1='68.541667' x2='101.50663' y2='68.541667' stroke={strokeColor} strokeWidth='2' />
            </svg>
          </div>
          <div className='esp-pressure-profile-tubing-image-container'>
            <div className='esp-pressure-profile-tubing-image' />
          </div>
          <div className='esp-pressure-profile-casing-left-top-container'>
            <svg preserveAspectRatio='none' width='3' height='115'>
              <line x1='0' y1='0' x2='0' y2='115' stroke={strokeColor} strokeWidth='3' />
            </svg>
          </div>
          <div className='esp-pressure-profile-casing-right-top-container'>
            <svg preserveAspectRatio='none' width='3' height='125'>
              <line x1='0' y1='0' x2='0' y2='125' stroke={strokeColor} strokeWidth='3' />
            </svg>
          </div>
          <div className='esp-pressure-profile-casing-plug-image-container'>
            <div className='esp-pressure-profile-casing-plug-image' />
          </div>
          <div className='esp-pressure-profile-dynamic-level-text-container'>
            <div className='esp-pressure-profile-dynamic-level-text'>
              <div>Dynamic</div>
              <div>Level</div>
            </div>
          </div>
          <div className='esp-pressure-profile-fluid-level-text-container'>
            <div className='esp-pressure-profile-fluid-level-text'>
              {pressureProfile?.fluidLevel?.value}
              {' (' + pressureProfile?.fluidLevel?.unit + ')'}
            </div>
          </div>
          <div className='esp-pressure-profile-well-depth-axis-container'>
            <svg preserveAspectRatio='none' width='3' height='300'>
              <line x1='0' y1='0' x2='0' y2='243' stroke={strokeColor} strokeWidth='3' />
            </svg>
          </div>
          <div className='esp-pressure-profile-well-depth-axis-label-container'>
            <div className='esp-pressure-profile-well-depth-axis-label'>Well Depth</div>
          </div>

          {/* Dynamic Static Gradient Line */}
          {/* Height scales in range of 122px - 222px */}
          <div
            className='static-gradient-line-container'
            style={{ height: `${0 + pumpDepthScale * (100 - 0) + 122}px` }}
          >
            <svg width='100%' height='100%' style={{ overflow: 'visible' }}>
              <defs>
                <linearGradient id='static-gradient' x1='0%' y1='0%' x2='100%' y2='100%'>
                  <stop offset='0%' stopColor='#DD48EC' />
                  <stop offset='100%' stopColor='#B93434' />
                </linearGradient>
              </defs>
              <line x1='0' y1='0' x2='100%' y2='100%' stroke='url(#static-gradient)' strokeWidth='1.5' />{' '}
            </svg>
          </div>

          {/* Dynamic Flowing Gradient Line */}
          {/* Height scales in range of 122px - 222px */}
          <div
            className='flowing-gradient-line-container'
            style={{ height: `${0 + pumpDepthScale * (100 - 0) + 122}px` }}
          >
            <svg width='100%' height='100%' style={{ overflow: 'visible' }}>
              <defs>
                <linearGradient id='flowing-gradient' x1='0%' y1='0%' x2='100%' y2='100%'>
                  <stop offset='0%' stopColor='#DD48EC' />
                  <stop offset='100%' stopColor='#648CB4' />
                </linearGradient>
              </defs>
              <line x1='0' y1='0' x2='100%' y2='100%' stroke='url(#flowing-gradient)' strokeWidth='1.5' />
            </svg>
          </div>

          <div className='esp-pressure-profile-pressure-axis-container'>
            <svg preserveAspectRatio='none' width='200' height='3'>
              <line x1='0' y1='0' x2='200' y2='0' stroke={strokeColor} strokeWidth='3' />
            </svg>
          </div>
          <div className='esp-pressure-profile-pressure-axis-label-container'>
            <div className='esp-pressure-profile-pressure-axis-label'>Pressure</div>
          </div>

          {/* Dynamic Pump Depth Image */}
          {/* Moves vertically in range of 0px - 100px */}
          <div
            className='esp-pressure-profile-dynamic-pump-depth-container'
            style={{ transform: `translateY(${0 + pumpDepthScale * (100 - 0)}px)` }}
          >
            <div className='pump-icon-container'>
              <div className='pump-icon' />
            </div>
            <div className='pump-depth-horizontal-line-container'>
              <svg preserveAspectRatio='none' width='283' height='3'>
                <line x1='0' y1='0' x2='283' y2='0' stroke={strokeColor} strokeWidth='3' />
              </svg>
            </div>
            <div className='pump-depth-label-container'>
              <div className='pump-depth-label'>Pump Depth</div>
            </div>
            <div className='pump-depth-value-container'>
              <div className='pump-depth-value'>
                {' '}
                {pressureProfile?.verticalPumpDepth?.value}
                {' (' + pressureProfile?.fluidLevel?.unit + ')'}
              </div>
            </div>
            <div className='casing-left-container'>
              <svg preserveAspectRatio='none' width='3' height='115'>
                <line x1='0' y1='0' x2='0' y2='115' stroke={strokeColor} strokeWidth='3' />
              </svg>
            </div>
            <div className='casing-right-container'>
              <svg preserveAspectRatio='none' width='3' height='115'>
                <line x1='0' y1='0' x2='0' y2='115' stroke={strokeColor} strokeWidth='3' />
              </svg>
            </div>
            <div className='gradient-box-container'>
              <div className='gradient-box' style={{ borderColor: strokeColor }} />
            </div>
            <div className='pip-point-container' style={{ transform: `translateX(${4.5 + pipTranslationFactor}px)` }}>
              <div className='pip-point-label'>PIP</div>
              <div className='pip-left-arrow-container'>
                <svg viewBox='296.63 329.5 11 9.38' width='10px' height='10px'>
                  <path
                    d='M307.125,338.375 L307.125,330 297.125,334 z'
                    fill={strokeColor}
                    stroke={strokeColor}
                    strokeWidth='2'
                  />
                </svg>
              </div>
              <div className='pip-left-arrow-point'>
                <svg width='20' height='20'>
                  <ellipse
                    cx='10'
                    cy='10'
                    rx='3'
                    ry='3'
                    fill={isDarkMode ? '#444f5e' : 'white'}
                    stroke='#40933C'
                    strokeWidth='1'
                  />
                </svg>
              </div>
              <div className='pip-flowing-gradient-delta-value'>
                {pressureProfile.pumpPressureDelta?.value !== undefined
                  ? Math.floor(pressureProfile.pumpPressureDelta.value)
                  : ''}
                {' ('}
                {pressureProfile.pumpPressureDelta?.unit ?? 'psi'}
                {')'}
              </div>
            </div>

            <div className='pdp-point'>
              <svg width='20' height='20'>
                <ellipse
                  cx='10'
                  cy='10'
                  rx='3'
                  ry='3'
                  fill={isDarkMode ? '#444f5e' : 'white'}
                  stroke='#648CB4'
                  strokeWidth='1'
                />
              </svg>
            </div>

            <div className='pdp-label'>PDPc</div>

            <div className='pump-friction-value'>
              {pressureProfile.pumpFrictionDelta?.value !== undefined
                ? Math.floor(pressureProfile.pumpFrictionDelta.value)
                : ''}
              {' ('}
              {pressureProfile.pumpFrictionDelta?.unit ?? 'psi'}
              {')'}
            </div>

            <div className='pip-right-arrow-container'>
              <svg viewBox='296.63 329.5 11 9.38' width='10px' height='10px'>
                <path
                  d='M307.125,338.375 L307.125,330 297.125,334 z'
                  fill={strokeColor}
                  stroke={strokeColor}
                  strokeWidth='2'
                />
              </svg>
            </div>

            <div className='psp-point'>
              <svg width='20' height='20'>
                <ellipse
                  cx='10'
                  cy='10'
                  rx='3'
                  ry='3'
                  fill={isDarkMode ? '#444f5e' : 'white'}
                  stroke='#B93434'
                  strokeWidth='1'
                />
              </svg>
            </div>

            <div className='psp-label'>PSP</div>
          </div>

          <div className='perforation-depth-line' style={{ width: `${91.5 + fbhpTranslationFactor}px` }}>
            <svg width='100%' height='2'>
              <line x1='0' y1='1' x2='100%' y2='1' stroke={strokeColor} strokeWidth='3' />
            </svg>
          </div>

          <div className='fbhp-point' style={{ transform: `translateX(${175 + fbhpTranslationFactor}px)` }}>
            <svg width='20' height='20'>
              <ellipse
                cx='10'
                cy='10'
                rx='3'
                ry='3'
                fill={isDarkMode ? '#444f5e' : 'white'}
                stroke='#A922C7'
                strokeWidth='1'
              />
            </svg>
          </div>

          <div className='fbhp-label' style={{ transform: `translateX(${190 + fbhpTranslationFactor}px)` }}>
            FBHP
          </div>

          <div className='casing-head-pressure-label-container'>
            <div className='casing-head-pressure-label'>CHP</div>
          </div>
          <div className='casing-head-pressure-line-1-container'>
            <svg preserveAspectRatio='none' width='3' height='123.5'>
              <line x1='0' y1='0' x2='0' y2='140' stroke='#52DC6B' strokeWidth='3' />
            </svg>
          </div>

          <div className='well-head-pressure-label-container'>
            <div className='well-head-pressure-label'>THP</div>
          </div>
        </div>
      </>
    );
  };

  const renderPressureProfileContent = (pressureProfile: ESPPressureProfileModel) => {
    return (
      <>
        <div className='esp-pressure-profile-graphic-container'>
          {pressureProfile !== undefined ? renderWellGraphic(pressureProfile) : null}
        </div>
        <div className='esp-pressure-profile-legend-container'>
          <div className='esp-pressure-profile-legend-container-column'>
            {renderPressureProfileLegendItem(
              'Pump Intake Pressure',
              pressureProfile?.pumpIntakePressure?.value,
              pressureProfile?.pumpIntakePressure?.unit,
              'psi',
              '#40933C',
            )}
            {renderPressureProfileLegendItem(
              'Pump Discharge Pressure (Calculated)',
              pressureProfile?.pumpDischargePressure?.value,
              pressureProfile?.pumpDischargePressure?.unit,
              'psi',
              '#648CB4',
            )}
            {renderPressureProfileLegendItem(
              'Pump Discharge Pressure (Sensor)',
              pressureProfile?.dischargeGaugePressure?.value,
              pressureProfile?.dischargeGaugePressure?.unit,
              'psi',
              '#648CB4',
            )}
            {renderPressureProfileLegendItem(
              'Pump Static Pressure',
              pressureProfile?.pumpStaticPressure?.value,
              pressureProfile?.pumpStaticPressure?.unit,
              'psi',
              '#B93434',
            )}
            {renderPressureProfileLegendItem(
              'ΔpPump',
              pressureProfile?.pumpPressureDelta?.value,
              pressureProfile?.pumpPressureDelta?.unit,
              'psi',
              '#FFA200',
            )}
            {renderPressureProfileLegendItem(
              'ΔPtr',
              pressureProfile?.pumpFrictionDelta?.value,
              pressureProfile?.pumpFrictionDelta?.unit,
              'psi',
              '#4BBDDE',
            )}
          </div>
          <div className='esp-pressure-profile-legend-container-column'>
            {renderPressureProfileLegendItem(
              'Casing Head Pressure',
              pressureProfile?.casingPressure?.value,
              pressureProfile?.casingPressure?.unit,
              'psi',
              '#52DC6B',
            )}
            {renderPressureProfileLegendItem(
              'Tubing Head Pressure',
              pressureProfile?.tubingPressure?.value,
              pressureProfile?.tubingPressure?.unit,
              'psi',
              '#DD48EC',
            )}
            {renderPressureProfileLegendItem(
              'FBHP',
              pressureProfile?.flowingBHP?.value,
              pressureProfile?.flowingBHP?.unit,
              'psi',
              '#A922C7',
            )}
            {renderPressureProfileLegendItem(
              'Flowing Gradient',
              pressureProfile?.flowingBHPGradient,
              pressureProfile?.pressureGradientUnits,
              'psi/ft',
              '#DD48EC',
            )}
            {renderPressureProfileLegendItem(
              'Static Gradient',
              pressureProfile?.staticGradient,
              pressureProfile?.pressureGradientUnits,
              'psi/ft',
              '#B93434',
            )}
          </div>
        </div>
      </>
    );
  };

  const renderErrorMessage = (errorMessage: string) => {
    console.log(errorMessage);
    return (
      <div className='error-message'>
        {errorMessage.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    );
  };

  return (
    <div className='esp-pressure-profile-container'>
      {pressureProfile === undefined
        ? null
        : pressureProfile.isValid
        ? renderPressureProfileContent(pressureProfile)
        : renderErrorMessage(pressureProfile.errorMessage)}
    </div>
  );
};

export default ESPPressureProfile;
