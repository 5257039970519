import Loader from '../Loader/Loader';

import UILabelItem, { UILabelItemProps } from '../UILabelItem/UILabelItem';
import './AnalysisInputOutput.css';

export interface AnalysisInputOutputProps {
  headerText: string;
  values: UILabelItemProps[] | undefined | null;
  loading: boolean | false;
}

const AnalysisInputOutput = (props: AnalysisInputOutputProps) => {
  return (
    <div className='flex-grow flex-shrink analysis-result-item-container'>
      <div className='analysis-result-item-header-container'>
        <label className='analysis-result-item-header-text whitespace-nowrap'>{props.headerText}</label>
      </div>
      {props.loading && <Loader />}
      <div className='analysis-input-output-body-container'>
        {props?.values?.map((x, index) => <UILabelItem key={index} id={x.id} title={x.title} value={x.value} />)}
      </div>
    </div>
  );
};

export default AnalysisInputOutput;
