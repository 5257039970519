import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Timeline.scss';
import { onDragStart } from './TimelineUtils';
import TimelineTooltip from './TimelineTooltip';
import TimelineScrollbar from './TimelineScrollbar';

interface TrendGroupTimelineProps {
  minDate: Date;
  maxDate: Date;
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  isFullScreen: boolean;
  onChangeDateRange: (startDate: Date, endDate: Date) => void;
  onChangeSelectedRange: (startDate: Date, endDate: Date) => void;
}

const TrendGroupTimeline: React.FC<TrendGroupTimelineProps> = ({
  minDate,
  maxDate,
  selectedStartDate,
  selectedEndDate,
  isFullScreen,
  onChangeDateRange,
  onChangeSelectedRange,
}) => {
  const startRef = useRef<number>(0);
  const endRef = useRef<number>(0);

  const [leftHandlePosition, setLeftHandlePosition] = useState<number>(0);
  const [rightHandlePosition, setRightHandlePosition] = useState<number>(0);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const [width, setWidth] = useState<number>(0);

  // extended start date and end date should be 3x the minDate and maxDate
  const extendedStartDate = new Date(minDate.getTime() - (maxDate.getTime() - minDate.getTime()));
  const extendedEndDate = new Date(maxDate.getTime() + (maxDate.getTime() - minDate.getTime()));

  const [startDate, setStartDate] = useState<Date>(extendedStartDate);
  const [endDate, setEndDate] = useState<Date>(extendedEndDate);

  const [scrollPixels, setScrollPixels] = useState<number>(0);

  const [handlePosition, setHandlePosition] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragOffset, setDragOffset] = useState<number>(0);
  const [finishedInitialRender, setFinishedInitialRender] = useState<boolean>(false);

  const scrollbarRef = useRef<HTMLDivElement>(null);
  const handleWidth = 103.84;

  const handleScrollbarScroll = (percentage: number) => {
    const scrollPixels = (percentage / 100 / 2) * width;
    setScrollPixels(scrollPixels);
  };

  const onChange = (start: Date, end: Date) => {
    onChangeDateRange(start, end);
    setHandlePosition(0.5 * scrollbarRef.current!.clientWidth - 51.92 - 20);
    setScrollPixels(width / 4);
  };

  useEffect(() => {
    if (width > 0) {
      setScrollPixels(width / 4);
      setHandlePosition(0.5 * scrollbarRef.current!.clientWidth - 51.92 - 20);
    }
  }, [width]);

  const moveEventListener = useRef<(e: MouseEvent) => void>(() => {
    null;
  });

  const mouseUpEventListener = useRef<(e: MouseEvent) => void>(() => {
    null;
  });

  const timelineRef = useRef<HTMLDivElement>(null);
  const draggingRef = useRef(false);

  const isDraggingBox = useRef<boolean>(false);
  const initialMouseX = useRef<number>(0);
  const initialStart = useRef<number>(0);

  useEffect(() => {
    if (!minDate || !maxDate) return;

    setStartDate(extendedStartDate);
    setEndDate(extendedEndDate);

    onChangeSelectedRange(minDate, maxDate);
  }, [minDate, maxDate]);

  useEffect(() => {
    startRef.current = 0;
    endRef.current = width;
    setLeftHandlePosition(0);
    setRightHandlePosition(0);
  }, [width]);

  const handleResize = () => {
    if (timelineRef.current) {
      setWidth(timelineRef.current.clientWidth * 2);
    }
  };

  if (timelineRef.current == null || width == 0) {
    handleResize();
  }

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
    setScrollPixels(width / 4);
    setHandlePosition(0.5 * scrollbarRef.current!.clientWidth - 51.92 - 20);
  }, [isFullScreen]);

  useEffect(() => {
    if (startDate && endDate && selectedStartDate && selectedEndDate) {
      if (selectedStartDate instanceof Date && selectedEndDate instanceof Date) {
        startRef.current =
          ((selectedStartDate.getTime() - extendedStartDate.getTime()) /
            (extendedEndDate.getTime() - extendedStartDate.getTime())) *
          width;
        endRef.current =
          ((selectedEndDate.getTime() - extendedStartDate.getTime()) /
            (extendedEndDate.getTime() - extendedStartDate.getTime())) *
          width;

        setLeftHandlePosition(startRef.current);
        setRightHandlePosition(width - endRef.current);
      }
    }
  }, [startDate, endDate, selectedStartDate, selectedEndDate, width]);

  const calculateDateTicks = useCallback((start: Date | null, end: Date | null, maxTicks: number) => {
    if (!start || !end) return [];
    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();
    const divisions = maxTicks;
    const diffTime = endDate - startDate;
    const interval = diffTime / divisions;
    const dates: Date[] = [];

    for (let i = 0; i <= divisions; i++) {
      const tickTime = startDate + i * interval;
      if (tickTime <= endDate) {
        dates.push(new Date(tickTime));
      }
    }

    return dates;
  }, []);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, isLeft: boolean, isBox: boolean) => {
    onDragStart(
      e,
      isLeft,
      isBox,
      draggingRef,
      timelineRef,
      isDraggingBox,
      initialMouseX,
      initialStart,
      moveEventListener,
      mouseUpEventListener,
      startRef,
      endRef,
      width,
      startDate,
      endDate,
      'trend-group-date-range-box',
      onChange,
      setLeftHandlePosition,
      setRightHandlePosition,
      scrollPixels,
    );
  };

  const maxTicks = 10;
  const dateTicks = calculateDateTicks(extendedStartDate, extendedEndDate, maxTicks);

  const handleMouseDown = (event: React.MouseEvent) => {
    if (scrollbarRef.current) {
      const rect = scrollbarRef.current.getBoundingClientRect();
      const offset = event.clientX - rect.left - handlePosition;
      setDragOffset(offset);
      setIsDragging(true);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (isDragging && scrollbarRef.current) {
      const rect = scrollbarRef.current.getBoundingClientRect();
      let newHandlePosition = event.clientX - rect.left - dragOffset;

      if (newHandlePosition < 0) newHandlePosition = 0;
      if (newHandlePosition > rect.width - handleWidth) newHandlePosition = rect.width - handleWidth;

      setHandlePosition(newHandlePosition);

      const percentage = (newHandlePosition / (rect.width - handleWidth)) * 100;
      handleScrollbarScroll(percentage);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    if (handlePosition === 0 && !finishedInitialRender && scrollbarRef.current) {
      setHandlePosition(0.5 * scrollbarRef.current.clientWidth - 51.92 - 20);
      setFinishedInitialRender(true);
    }
  }, [handlePosition, finishedInitialRender]);

  const moveHandle = (direction: 'left' | 'right') => {
    if (scrollbarRef.current) {
      const rect = scrollbarRef.current.getBoundingClientRect();
      const moveAmount = 40;
      let newHandlePosition = handlePosition + (direction === 'left' ? -moveAmount : moveAmount);

      if (newHandlePosition < 0) newHandlePosition = 0;
      if (newHandlePosition > rect.width - handleWidth) newHandlePosition = rect.width - handleWidth;

      setHandlePosition(newHandlePosition);

      const percentage = (newHandlePosition / (rect.width - handleWidth)) * 100;
      handleScrollbarScroll(percentage);
    }
  };

  return startDate && endDate ? (
    <div ref={timelineRef} className='timeline-outer-container' onMouseDown={(e) => e.preventDefault()}>
      <div
        className='timeline-tooltip-container'
        style={{
          left: leftHandlePosition < width - 100 ? Math.max(leftHandlePosition - 100, 0) : width - 199,
          right: Math.max(rightHandlePosition - 100, 0),
        }}
      >
        <TimelineTooltip
          active={showTooltip || draggingRef.current}
          label={
            selectedStartDate?.toLocaleString('en', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }) ?? ''
          }
        />
        <TimelineTooltip
          active={showTooltip || draggingRef.current}
          label={
            selectedEndDate?.toLocaleString('en', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }) ?? ''
          }
        />
      </div>
      <div
        className='timeline-border-container'
        style={{
          width: '100%',
          border: '1px solid #e0e0e0',
        }}
      >
        <div
          className='timeline-container'
          style={{
            width: width,
          }}
        >
          <div
            onMouseDown={(e) => e.preventDefault()}
            className='timeline-inner-container'
            style={{
              transform: `translateX(-${scrollPixels}px)`,
              transition: 'transform 0.1s ease',
              width: `${width}px`,
            }}
          >
            {dateTicks.map((date, index) => (
              <div
                key={index}
                className='timeline-tick'
                style={{
                  left: `${(index / (dateTicks.length - 1)) * 100}%`,
                }}
              >
                <div className='timeline-tick-line' />
                {index !== dateTicks.length - 1 && (
                  <span className='timeline-tick-label'>
                    {date.toLocaleString('en', {
                      day: 'numeric',
                      month: 'short',
                    })}
                  </span>
                )}
              </div>
            ))}

            <div
              id='trend-group-date-range-box'
              onMouseDown={(e) => onMouseDown(e, false, true)}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className='timeline-date-range-box'
              style={{
                left: leftHandlePosition,
                right: rightHandlePosition,
              }}
            >
              <div onMouseDown={(e) => onMouseDown(e, true, false)} className='timeline-handle-left'>
                <div className='timeline-handle-line' />
                <div className='timeline-handle-line' />
              </div>

              <div onMouseDown={(e) => onMouseDown(e, false, false)} className='timeline-handle-right'>
                <div className='timeline-handle-line' />
                <div className='timeline-handle-line' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TimelineScrollbar
        scrollbarRef={scrollbarRef}
        handleWidth={handleWidth}
        handlePosition={handlePosition}
        moveHandle={moveHandle}
        handleMouseDown={handleMouseDown}
      />
    </div>
  ) : null;
};

export default TrendGroupTimeline;
