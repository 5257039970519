import React from 'react';
import './PerformanceCurveLegend.scss';
import { RGBColor, SelectedCardTrend } from '../../../../models/Card';

interface LegendType {
  id: number;
  name: string;
  color: string;
}

interface PerformanceCurveLegendProps {
  activeLegendItems: SelectedCardTrend[];
  cardColor: RGBColor | undefined;
}

const PerformanceCurveLegend = ({ activeLegendItems, cardColor }: PerformanceCurveLegendProps) => {
  const labels = [
    {
      id: 1,
      name: 'Production performance curve',
      color: '#884dd5',
    },
    {
      id: 2,
      name: 'Pressure performance curve',
      color: '#FF0000',
    },
    {
      id: 27,
      name: 'Flowing bottomhole pressure performance curve',
      color: '#f97066',
    },
    {
      id: -1,
      name: 'Coleman crit flowrate at surface',
      color: '#4294ff',
    },
    {
      id: -4,
      name: 'Coleman crit flowrate at injection depth',
      color: '#32d583',
    },
    {
      id: -5,
      name: 'Mod* thornhill-craver valve CV',
      color: '#98ceca',
    },
    {
      id: -2,
      name: 'Production operating point',
      color: '#0000FF',
    },
    {
      id: -3,
      name: 'Pressure operating point',
      color: '#FF0000',
    },
  ];

  const isLegendActive = (label: LegendType) => {
    if (!label || !label.id || !activeLegendItems.length) return;
    return activeLegendItems.map((activeLegend) => activeLegend.id).includes(label.id);
  };

  return (
    <div className='performance-curve-legend-container'>
      <div className='performance-curve-legend-items'>
        {labels.map(
          (label) =>
            activeLegendItems &&
            isLegendActive(label) && (
              <div key={label.id} className='flex flex-row items-center justify-center'>
                <div
                  className='performance-curve-legend-circle'
                  style={{
                    backgroundColor: cardColor ? `rgb(${cardColor.r}, ${cardColor.g}, ${cardColor.b})` : label.color,
                  }}
                />
                <div className='performance-curve-legend-label'>{label.name}</div>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default PerformanceCurveLegend;
