import React from 'react';
import '../../rod-lift-analysis/CardDateItem/CardDateItem.css';
import { RGBColor } from '../../../models/Card';

interface WellTestItemProps {
  id: number;
  date: string;
  name: string;
  isSelected: boolean;
  onClickAction: (id: number, date: string) => void;
  color: RGBColor | null;
  style?: React.CSSProperties;
}

export const WellTestItem = (props: WellTestItemProps) => {
  const styles = {
    background: props.color ? `rgb(${props.color.r},${props.color.g},${props.color.b})` : '#005F7C',
  };

  return (
    <div
      className='card-date-item-container'
      onClick={() => props.onClickAction(props.id, props.date)}
      style={props.style}
    >
      <div style={props.isSelected ? styles : {}} className='card-date-item-chart-color' />
      <div
        className={
          props.isSelected
            ? 'card-date-item-item-container card-date-item-item-container-active'
            : 'card-date-item-item-container'
        }
      >
        <div className='card-date-item-text-container'>
          <label className='card-date-item-text card-date-item-card-type-text'> {props.name} </label>
          <label className='card-date-item-text card-date-item-card-date-text'> {props.date} </label>
        </div>
      </div>
    </div>
  );
};

export default WellTestItem;
