import React from 'react';
import './PressureProfileLegend.scss';
import PressureProfileChartColors from './presureProfileChartColors';

interface PressureProfileLegendProps {
  legendItems: Array<{ id: number; name: string; color: string | undefined }>;
}

const PressureProfileLegend = (props: PressureProfileLegendProps) => {
  return (
    <div className='pressure-profile-legend-container'>
      <div className='pressure-profile-legend-items'>
        {props.legendItems.map((label) => {
          const colorItem = PressureProfileChartColors.find((item) => item.id === label.id);
          const color = colorItem ? colorItem.color : '#006DFA';

          return (
            <div key={label.id} className='flex flex-row items-center justify-center'>
              <div className='pressure-profile-legend-dash' style={{ backgroundColor: color }} />
              <div className='pressure-profile-legend-label'>{label.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PressureProfileLegend;
