import React from 'react';
import './CardDateItem.css';
import { ReactComponent as Brush } from '../../../images/brush-03.svg';

interface RGBColor {
  r: number;
  g: number;
  b: number;
}

interface CardDateItemProps {
  id: number;
  date: string;
  name: string;
  isSelected: boolean;
  analysisTypeId: number;
  analysisResultId: number;
  onClickAction: (id: number, date: string, analysisResultId: number, analysisTypeId: number) => void;
  showIcon: boolean;
  color: RGBColor | null;
  style?: React.CSSProperties;
}

export const CardDateItem = (props: CardDateItemProps) => {
  const styles = {
    background: props.color ? `rgb(${props.color.r}, ${props.color.g}, ${props.color.b})` : '#005F7C',
  };

  return (
    <div
      className='card-date-item-container'
      style={props.style}
      onClick={() => props.onClickAction(props.id, props.date, props.analysisResultId, props.analysisTypeId)}
    >
      <div style={props.isSelected ? styles : {}} className='card-date-item-chart-color' />
      <div
        className={
          props.isSelected
            ? 'card-date-item-item-container card-date-item-item-container-active'
            : 'card-date-item-item-container'
        }
      >
        <div className='card-date-item-text-container'>
          <label className='card-date-item-text card-date-item-card-type-text'> {props.name} </label>
          <label className='card-date-item-text card-date-item-card-date-text secondary'> {props.date} </label>
        </div>
        {props.showIcon && props.isSelected ? (
          <div className='brush-icon-container'>
            <Brush />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CardDateItem;
