import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { fetchDataHistoryTrendLibrary, TrendResponse, ItemResponse } from '../DataHistoryService';

export interface Item {
  id: string;
  name: string;
  typeId: number;
}

interface chartGroupType {
  groupName: string;
  selectedTrendsArray: any[];
}

interface State {
  loading: boolean;
  trends: Array<Trend> | null | undefined;
  searchTrends: Array<Trend> | null | undefined;
  error: string | undefined;
  selectedItemId: string | undefined;
  selectedItemGuid: string | undefined;
  selectedItem: Item | undefined;
  items: Array<Item> | null | undefined;
  selectedTrend: Trend | undefined;
  trendHashMap: Record<number, Trend>;
  isFiltered: boolean;
  socketId: string | undefined;
  checkedItems: Item[];
  ischeckBoxUpdated: boolean;
  groupCheckedItems: {
    chart1: [];
    chart2: [];
    chart3: [];
    chart4: [];
  };
  itemToTrendMap: Record<string, string>;
  trendsGroupSelection: chartGroupType[];
}

interface ActionPayload {
  trendId: number | undefined;
  itemId: string | undefined;
  searchString: string | undefined;
  itemIdGuid: string | undefined;
}

interface SocketActionPayload {
  socketId: string | undefined;
}

export interface Trend {
  trendId: number;
  itemTrendName: string;
  childTrends: Trend[];
  items: Item[];
  showItems: boolean;
  class: string;
}

interface FetchParameters {
  assetId: string;
  groupName: string | undefined;
}

// Async thunk to fetch data history trends
export const fetchDataHistoryTrendLibraryAsync = createAsyncThunk(
  'trendLibrarySlice/fetchDataHistoryTrendLibrary',
  async (trend: FetchParameters) => {
    const data = await fetchDataHistoryTrendLibrary(trend.assetId, trend.groupName);
    const trendData = data.values;
    const trends = mapTrendResponseToTrend(trendData);
    const trendHashMap = createTrendHashMap(trends);
    const items = Object.values(trendHashMap).flatMap((trend) => trend.items);

    const itemToTrendMap: Record<string, string> = {};
    trends.forEach((trend) => {
      trend.items.forEach((item) => {
        itemToTrendMap[item.name] = trend.itemTrendName;
      });
    });

    return { trends, trendHashMap, items, itemToTrendMap };
  },
);

const createTrendHashMap = (trends: Trend[]): Record<number, Trend> => {
  const hashMap: Record<number, Trend> = {};
  const addToHashMap = (trend: Trend) => {
    hashMap[trend.trendId] = trend;
    trend.childTrends.forEach(addToHashMap);
  };
  trends.forEach(addToHashMap);
  return hashMap;
};

let trendId = 1;

const mapTrendResponseToTrend = (trends: TrendResponse[]): Trend[] =>
  trends.map((trend: TrendResponse) => ({
    trendId: trendId++,
    itemTrendName: trend.name,
    childTrends: trend.childTrends?.length ? mapTrendResponseToTrend(trend.childTrends) : [],
    items:
      trend.items?.map(
        (item: ItemResponse): Item => ({
          id: item.id,
          name: item.name,
          typeId: item.typeId,
        }),
      ) ?? [],
    showItems: false,
    class: 'trend-library-content-frame-item',
  }));

const initialState: State = {
  loading: false,
  trends: null,
  searchTrends: null,
  error: undefined,
  selectedItemId: undefined,
  selectedItem: undefined,
  items: null,
  selectedTrend: undefined,
  trendHashMap: {},
  isFiltered: false,
  socketId: undefined,
  selectedItemGuid: undefined,
  checkedItems: [],
  groupCheckedItems: { chart1: [], chart2: [], chart3: [], chart4: [] },
  itemToTrendMap: {},
  trendsGroupSelection: [
    { groupName: 'Trend group 1', selectedTrendsArray: [] },
    { groupName: 'Trend group 2', selectedTrendsArray: [] },
    { groupName: 'Trend group 3', selectedTrendsArray: [] },
    { groupName: 'Trend group 4', selectedTrendsArray: [] },
  ],
  ischeckBoxUpdated: false,
};

const updateTrendTree = (trends, trendId, newTrend) => {
  if (!trends) return null;
  return trends.map((trend) => {
    if (trend.id === trendId) {
      return newTrend;
    } else {
      return {
        ...trend,
        trendTrends: updateTrendTree(trend.childTrends, trendId, newTrend),
      };
    }
  });
};

const resetShowItems = (trends) => {
  if (!trends) return null;
  return trends.map((trend: Trend) => {
    return {
      ...trend,
      showItems: false,
      childTrends: resetShowItems(trend.childTrends),
      class: 'trend-library-content-frame-item',
    };
  });
};

const setTrendHashMapDisplayState = (trendHashMap: Record<number, Trend>, active: boolean): Record<number, Trend> => {
  const resetTrendDisplayState = (trend: Trend): Trend => ({
    ...trend,
    showItems: active,
    class: active ? 'trend-library-content-frame-item item-selected' : 'trend-library-content-frame-item',
    childTrends: trend.childTrends.map(resetTrendDisplayState),
  });

  const newHashMap = { ...trendHashMap };
  Object.keys(newHashMap).forEach((key) => {
    newHashMap[key] = resetTrendDisplayState(newHashMap[key]);
  });
  return newHashMap;
};

export const trendLibrarySlice = createSlice({
  name: 'trendLibrary',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    updateItemsDisplayState: (state, action: PayloadAction<ActionPayload>) => {
      const id = action.payload.trendId;
      if (!id) {
        return;
      }

      const trend = state.trendHashMap[id];

      if (trend) {
        const showItems = !trend.showItems;
        state.trendHashMap[id] = {
          ...trend,
          showItems,
          class: showItems ? 'trend-library-content-frame-item item-selected' : 'trend-library-content-frame-item',
        };
        state.selectedTrend = state.trendHashMap[id];
        state.trends = updateTrendTree(state.trends, id, state.trendHashMap[id]);
        state.searchTrends = updateTrendTree(state.searchTrends, id, state.trendHashMap[id]);
      }
    },
    updateSelectedItem: (state, action: PayloadAction<ActionPayload>) => {
      state.selectedItemId = action.payload.itemId;
      state.selectedItem = state.items?.find((item) => item.id === action.payload.itemId);
      state.selectedItemGuid = action.payload.itemIdGuid;

      if (action.payload.trendId) {
        state.selectedTrend = state.trendHashMap[action.payload.trendId];
      } else {
        state.selectedTrend = undefined;
      }
    },
    updateSocketId: (state, action: PayloadAction<SocketActionPayload>) => {
      state.socketId = action.payload.socketId;
    },
    updateSearchTrends: (state, action: PayloadAction<ActionPayload>) => {
      const searchString = action.payload.searchString?.toLowerCase();
      state.searchTrends = resetShowItems(state.trends);
      if (searchString) {
        state.trendHashMap = setTrendHashMapDisplayState(state.trendHashMap, true);

        const allTrends = Object.values(state.trendHashMap);
        state.searchTrends = allTrends
          .filter((trend: Trend) => trend.items.some((item) => item.name.toLowerCase().startsWith(searchString)))
          .map((trend: Trend) => ({
            ...trend,
            showItems: true,
            items: trend.items.filter((item) => item.name.toLowerCase().startsWith(searchString)),
          }));
      } else {
        state.trendHashMap = setTrendHashMapDisplayState(state.trendHashMap, false);
      }
    },
    updateCheckedItems: (state, action: PayloadAction<Item[]>) => {
      state.checkedItems = action.payload;
    },
    updateGroupCheckedBoxes: (state, action) => {
      state.groupCheckedItems = action.payload;
    },
    clearSearchFilter: (state) => {
      state.searchTrends = resetShowItems(state.trends);
      state.trendHashMap = setTrendHashMapDisplayState(state.trendHashMap, false);
    },
    updateGroupedChartSelection: (state, action) => {
      state.trendsGroupSelection = action.payload;
    },
    updateCheckBoxFlag: (state, action) => {
      state.ischeckBoxUpdated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataHistoryTrendLibraryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDataHistoryTrendLibraryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.trends = action.payload.trends;
        state.trendHashMap = action.payload.trendHashMap;
        state.searchTrends = action.payload.trends;
        state.items = action.payload.items;
        state.itemToTrendMap = action.payload.itemToTrendMap;
      })
      .addCase(fetchDataHistoryTrendLibraryAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  updateItemsDisplayState,
  updateSelectedItem,
  updateSearchTrends,
  updateSocketId,
  updateCheckedItems,
  updateCheckBoxFlag,
  updateGroupCheckedBoxes,
  clearSearchFilter,
  updateGroupedChartSelection,
  setInitialState,
} = trendLibrarySlice.actions;

export const selectChildTrends = (state: RootState) => state.trendLibrary;

export const selectedTrendItems = (state: RootState) => {
  const { checkedItems, itemToTrendMap, trends, items } = state.trendLibrary;

  if (!trends || !items) return [];
  const checkItemNames = checkedItems.map((item) => item.name);

  return checkItemNames.map((itemId) => {
    const trendName = trends.find((trend) => trend.itemTrendName === itemToTrendMap[itemId])?.itemTrendName;
    const itemName = items.find((item) => item.name === itemId)?.name || 'Unknown Item';
    return { itemName, trendName, itemId };
  });
};

export default trendLibrarySlice.reducer;
