import React, { FC } from 'react';
import './PropertyValueList.css';

interface IPropertyValue {
  id: number;
  description: string;
  value?: string | null;
  displayState?: number | null;
}

interface IPropertyValueList {
  description: string;
  properties: IPropertyValue[];
  showLength: boolean;
  maxHeight?: string;
}

const DisplayStatusState = {
  Normal: 0,
  OK: 1,
  Warning: 2,
  Error: 3,
  Fatal: 4,
  Emphasis: 5,
} as const;

const DisplayStatusStateCssClassMap = new Map<number, string>([
  [DisplayStatusState.Normal, ''],
  [DisplayStatusState.OK, 'good'],
  [DisplayStatusState.Warning, 'warning'],
  [DisplayStatusState.Error, 'error'],
  [DisplayStatusState.Fatal, 'fatal'],
  [DisplayStatusState.Emphasis, 'emphasis'],
]);

function GetDataOverlayCssClass(pv: IPropertyValue): string {
  return DisplayStatusStateCssClassMap.get(pv.displayState ?? 0) ?? '';
}

export const PropertyListCard: FC<IPropertyValueList> = ({ description, properties, showLength, maxHeight }) => {
  return (
    <div className='card asset-status-card property-list'>
      <div className='header'>
        <span className='title property-value-title-width'>
          {description}
          {showLength && <span className='property-value-redsquare'>{properties.length}</span>}
        </span>
      </div>
      <div
        style={{
          maxHeight: maxHeight && maxHeight.length > 0 ? maxHeight : '100%',
          overflow: 'auto',
        }}
      >
        {properties.length === 0 && (
          <div className='property-list-item'>
            <span className='description'>None</span>
          </div>
        )}

        {properties.length > 0 &&
          properties.map((p: IPropertyValue) => {
            return (
              <div key={p.id} className='property-list-item'>
                <span className='description'>{p.description}:</span>
                <span className={`value ${GetDataOverlayCssClass(p)}`}>{p.value ?? '——'}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PropertyListCard;
