export enum CurveType {
  ESPOperatingPoint = -6,
  ThornhillCraverValve = -5,
  ColemanCritFlowrateDepth = -4,
  PressureOperatingPoint = -3,
  ProductionOperatingPoint = -2,
  ColemanCritFlowrateSurface = -1,
  ProductionPerformance = 1,
  PressurePerformance = 2,
  GasInjectionPressure = 4,
  ReservoirFluidHydrostatic = 5,
  KillFluidHydrostatic = 6,
  Temperature = 7,
  ProductionFluidPressure = 8,
  IPRCurve = 19,
  FlowingBottomholePressure = 27,
  InjectionRateForCriticalVelocity = 29,
  TemperatureSurveyCurve = 25,
  PressureSurveyCurve = 26,
}
