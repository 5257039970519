import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

export const CreateXAxis = (props) => {
  const xAxis = props[0].chart.xAxes.push(
    am5xy.ValueAxis.new(props[0].root, {
      renderer: am5xy.AxisRendererX.new(props[0].root, { minGridDistance: 100 }),
      min: props[0].minX,
      max: props[0].maxX,
      strictMinMax: true,
      fill: props[0].color,
    }),
  );

  xAxis.children.push(
    am5core.Label.new(props[0].root, {
      text: 'Pressure (psi)',
      x: am5core.p50,
      centerX: am5core.p50,
      marginTop: -5,
      marginBottom: 5,
      fontSize: 12,
      fontWeight: '400',
      fill: props[0].color,
    }),
  );

  const xAxisRenderer = xAxis.get('renderer');
  xAxisRenderer.grid.template.setAll({
    strokeDasharray: [2.5],
    strokeOpacity: 1,
    strokeWidth: 1,
    stroke: am5core.color('#D0D8DD'),
  });

  return xAxis;
};
