import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchGLAnalysisSurveyDateInitialState } from './GLAnalysisService';
import { RGBColor } from '../../models/Card';
import { getCardColor } from '../../utilities/CardColorGenerator';

interface State {
  loading: boolean;
  glAnalysisSurveyDates: SurveyDataData[] | null | undefined;
  selectedCardDates: string[];
  selectedCardIds: number[];
  error: string | undefined;
}

export interface SurveyDataData {
  id: number;
  date: string;
  analysisTypeId: number;
  analysisResultId: number;
  analysisTypeName: string;
  color: RGBColor | null;
  isSelected: boolean;
}

interface GlAnalysisAssetId {
  assetId: string;
}

// Async thunk to fetch the initial state data
export const fetchSurveyDateInitialStateAsync = createAsyncThunk(
  'glAnalysisSurveyDateSlice/fetchSurveyDateInitialState',
  async (param: GlAnalysisAssetId) => {
    const data = await fetchGLAnalysisSurveyDateInitialState(param.assetId);
    return data;
  },
);

const initialState: State = {
  loading: false,
  glAnalysisSurveyDates: null,
  selectedCardDates: [],
  selectedCardIds: [],
  error: undefined,
};

export const glAnalysisSurveyDateSlice = createSlice({
  name: 'glAnalysisSurveyDateData',
  initialState,
  reducers: {
    setSurveyInitialState() {
      return initialState;
    },
    toggleSurveySelected(state, action: PayloadAction<number>) {
      const id = action.payload;
      state.selectedCardDates = [];

      if (!state.selectedCardIds.includes(id) && state.selectedCardIds.length >= 50) {
        alert('You can add only 50 cards to the chart.');
        return;
      }

      state.glAnalysisSurveyDates?.forEach((x, _, array) => {
        if (x.id == id) {
          x.isSelected = !x.isSelected;
        }
        if (x.isSelected) {
          state.selectedCardDates.push(x.date);
          x.color = getCardColor(array.findIndex((d) => d.id == x.id) + 1);
        }
      });
      if (state.selectedCardDates.length === 1) {
        state.glAnalysisSurveyDates?.forEach((x) => {
          x.color = null;
        });
      }

      state.glAnalysisSurveyDates?.forEach((x) => {
        if (x.id === id) {
          if (state.selectedCardIds.includes(id) && !x.isSelected) {
            state.selectedCardIds = state.selectedCardIds.filter((cardId) => cardId !== id);
          } else {
            if (!state.selectedCardIds.includes(id)) {
              state.selectedCardIds.push(id);
            }
          }
        }
      });
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveyDateInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSurveyDateInitialStateAsync.fulfilled, (state, action) => {
        state.loading = false;

        let id = 1;
        const glAnalysisSurveyTests: SurveyDataData[] = action.payload.values ?? [];

        glAnalysisSurveyTests.forEach((x: SurveyDataData) => {
          x.isSelected = false;

          // assign an id to each card
          x.id = id++;
        });

        state.glAnalysisSurveyDates = glAnalysisSurveyTests;
      })
      .addCase(fetchSurveyDateInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { toggleSurveySelected, setSurveyInitialState } = glAnalysisSurveyDateSlice.actions;
export default glAnalysisSurveyDateSlice.reducer;
