import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../common/tooltip/ToolTip';
import './GroupStatus.scss';

interface CustomTableProps {
  rawData: object;
  index: number;
  row: any;
  rowClassName: string;
  style: React.CSSProperties;
  href: string;
  handleClick;
}

const GroupStatusRow: React.FC<CustomTableProps> = ({ rawData, row, rowClassName, style, href, handleClick }) => {
  const navigate = useNavigate();

  const handleAnchorClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      navigate(href);
    },
    [navigate, href],
  );

  return (
    <div onClick={handleClick} className={rowClassName} style={style}>
      {row.cells.map((cell, cellIndex) => {
        const isLastCell = row.cells.length - 2 === cellIndex || row.cells.length - 3 === cellIndex;
        const wellName = row.cells.filter((column) => column.column.Header == 'Well')[0].value;
        const rowRawData = rawData.filter((row) => row.Well.value == wellName)[0];
        if (cell.column.Header !== 'Assetguid') {
          let className = 'td table-row-general';
          let cellValue = cell.render('Cell');
          const cellDetails = rowRawData[cell.column.id];

          switch (cell.column.id.toLowerCase()) {
            case 'well':
              className = `td table-row-name ${rowClassName}`;
              cellValue = (
                <a key={cellIndex} href='#' className='table-row-link' onClick={handleAnchorClick}>
                  {cell.render('Cell')}
                </a>
              );
              break;
            case '7':
              cellValue = (
                <div key={cellIndex} className='alarm-badges'>
                  {cell.value?.map((alarm: string, alarmIndex: number) => (
                    <div key={alarmIndex} className='badge'>
                      <span className='badge-text'>{alarm}</span>
                    </div>
                  ))}
                </div>
              );
              break;
            default:
              break;
          }

          if (cellDetails.foreColor && cellDetails.backColor && cell.value) {
            cellValue = (
              <div key={cellIndex} className='alarm-badges'>
                <div
                  className='badge'
                  style={{
                    backgroundColor: 'transparent',
                    color: cellDetails.backColor,
                    border: `1px solid ${cellDetails.backColor}`,
                  }}
                >
                  {cell.render('Cell')}
                </div>
              </div>
            );
          }

          if (!cellDetails.showToolTip) {
            return (
              <div {...cell.getCellProps()} className={`table-row-value ${className}`}>
                {cellValue}
              </div>
            );
          } else {
            const tooltipDirection = isLastCell ? 'Left' : 'right';

            const toolTipText = cellDetails.value;

            return (
              <div {...cell.getCellProps()} className={`table-row-value ${className}`}>
                <Tooltip content={toolTipText} direction={tooltipDirection} isGroupStatus={true}>
                  {cellValue}
                </Tooltip>
              </div>
            );
          }
        }
        return null;
      })}
    </div>
  );
};

export default React.memo(GroupStatusRow);
