import React, { useState, useRef, useMemo } from 'react';
import './TrendLibrary.scss';
import { Menu } from '@headlessui/react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import {
  updateItemsDisplayState,
  updateSearchTrends,
  updateSelectedItem,
  updateGroupCheckedBoxes,
  Trend,
  updateGroupedChartSelection,
} from './TrendLibrarySlice';
import { fetchTrendCoordinatesItemAsync, groupChartcoordinates } from './TrendCoordinateSlice';
import { ReactComponent as SearchIcon } from '../../../images/search-md.svg';
import { useParams } from 'react-router-dom';
import { Checkbox } from '../../common/CustomCheckbox/CheckBox';
import { fetchInitialStateAsync } from '../../asset-analysis/TrendGroupLabel/TrendGroupLabelSlice';
import { updategroupChartCoordinates, updateIsTrendLibraryClicked } from './TrendCoordinateSlice';
import { TrendLibraryProps, UrlParams, TrendItemType, ChartType } from './ChartTypes';
import { ReactComponent as ChevronDownIcon } from '../../../images/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../../images/chevron-up.svg';
import { clearSearchFilter } from '../../data-history/TrendLibrary/TrendLibrarySlice';
import { useClickOutside } from '../../common/Dropdown/DropdownClick';
import { currentChart, getParams } from './TrendLibraryUtility';

const RESTRICTING_CHART_VALUE = 4;

const TrendLibraryGrouped: React.FC<TrendLibraryProps> = ({ buttonContent, startDate, endDate, chartIndex }) => {
  const dispatch = useAppDispatch();
  const { assetId } = useParams<keyof UrlParams>() as UrlParams;
  const trendLibrary = useAppSelector((state) => state.trendLibrary);
  const trendItems = useAppSelector((state) => state.trendLibrary?.searchTrends);
  const isFiltered = useAppSelector((state) => state.trendLibrary.isFiltered);
  const selectedAssetId = useAppSelector((state) => state.assetGroups?.selectedAssetGuid);
  const groupCheckedItems = useAppSelector((state) => state.trendLibrary.groupCheckedItems);

  const groupCoordinates = useAppSelector(groupChartcoordinates);
  const trendsGroupSelection = useAppSelector((state) => state.trendLibrary.trendsGroupSelection);

  const chart = currentChart(chartIndex);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (trendItems && trendItems?.length > 0) {
        const itemId = trendItems[0].items[0].name;
        const itemIdGuid = trendItems[0].items[0].id;

        dispatch(
          updateSelectedItem({
            trendId: trendItems[0].trendId,
            itemId: itemId,
            searchString: undefined,
            itemIdGuid: itemIdGuid,
          }),
          fetchInitialStateAsync(selectedAssetId, itemIdGuid),
        );
      }
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSearchTrends({
        trendId: undefined,
        itemId: undefined,
        searchString: e.target.value,
        itemIdGuid: undefined,
      }),
    );
  };

  const checkedItemsNames = useMemo(() => {
    return groupCheckedItems[chart] && groupCheckedItems[chart].length
      ? groupCheckedItems[chart].map((item) => {
          if (item) return item.name;
        })
      : [];
  }, [groupCheckedItems]);

  const handleCheckboxChange = (trendItem: TrendItemType) => {
    dispatch(updateIsTrendLibraryClicked(true));
    const selectedGroup = groupCheckedItems[chart];
    const trendsGroupSelectionClone = [...trendsGroupSelection];
    const objClone = { ...trendsGroupSelectionClone[chartIndex] };

    if (selectedGroup.findIndex((item) => item.name === trendItem.name) > -1) {
      dispatch(
        updateGroupCheckedBoxes({
          ...groupCheckedItems,
          [chart]: [...groupCheckedItems[chart]].filter((item) => item.name !== trendItem.name),
        }),
      );
      dispatch(
        updategroupChartCoordinates({
          value: {
            ...groupCoordinates,
            [chart]: groupCoordinates[chart].filter((item) => item.name !== trendItem.name),
          },
          type: ChartType.Group,
        }),
      );
      removeTrendfromChart(trendItem, objClone, trendsGroupSelectionClone);
    } else {
      if (objClone.selectedTrendsArray && objClone.selectedTrendsArray.length < RESTRICTING_CHART_VALUE) {
        dispatch(
          updateGroupCheckedBoxes({
            ...groupCheckedItems,
            [chart]: [...groupCheckedItems[chart], trendItem],
          }),
        );
        if (!startDate || !endDate) return;
        const formattedStartDate = new Date(startDate).toISOString().slice(0, 19).replace('T', ' ');
        const formattedEndDate = new Date(endDate).toISOString().slice(0, 19).replace('T', ' ');

        const params: any = getParams(assetId, formattedStartDate, formattedEndDate, chartIndex, trendItem);
        dispatch(fetchTrendCoordinatesItemAsync(params));

        addTrendOnGroupChart(trendItem, objClone, trendsGroupSelectionClone);
      } else {
        alert('You can add only 4 charts as of now');
      }
    }
  };

  const addTrendOnGroupChart = (trendItem, objClone, trendsGroupSelectionClone) => {
    const selectedArrayClone: any = [];
    selectedArrayClone.push(...objClone.selectedTrendsArray, trendItem);
    trendsGroupSelectionClone[chartIndex] = Object.assign(objClone, { selectedTrendsArray: selectedArrayClone });
    dispatch(updateGroupedChartSelection(trendsGroupSelectionClone));
  };

  const removeTrendfromChart = (trendItem, objClone, trendsGroupSelectionClone) => {
    trendsGroupSelectionClone[chartIndex] = Object.assign(objClone, {
      selectedTrendsArray: [...objClone.selectedTrendsArray].filter((trend) => trend.name !== trendItem.name),
    });
    dispatch(updateGroupedChartSelection(trendsGroupSelectionClone));
  };

  const handleItemTrendNameClick = (trendId: number) => {
    dispatch(
      updateItemsDisplayState({
        trendId: trendId,
        itemId: undefined,
        searchString: undefined,
        itemIdGuid: undefined,
      }),
    );
  };
  const handleItemIdClick = (
    itemName: string | undefined,
    trendId: number,
    itemId: string | undefined,
    isChecked: boolean,
  ) => {
    dispatch(updateSelectedItem({ trendId: trendId, itemId: itemName, searchString: undefined, itemIdGuid: itemId }));
    if (Number.isInteger(parseInt(itemId, 10)) && isChecked) {
      dispatch(fetchInitialStateAsync({ selectedAssetId, address: itemId }));
    }
  };
  const handleTrendItemClick = (id: number) => {
    dispatch(
      updateItemsDisplayState({
        trendId: id,
        itemId: undefined,
        searchString: undefined,
        itemIdGuid: undefined,
      }),
    );
  };

  const renderTrend = (trend: Trend, index: number, indentLevel = 0) => {
    return (
      <div key={trend.itemTrendName + index.toString()}>
        <div className={trend.class} onClick={() => handleTrendItemClick(trend.trendId)}>
          <div className='trend-library-frame-item-content'>
            <div
              data-testid={`trend-${trend.itemTrendName}`}
              className='trend-library-content-frame-item-text'
              onClick={() => handleItemTrendNameClick(trend.trendId)}
            >
              {trend.itemTrendName}
            </div>
          </div>
          <div className='trend-library-content-frame-item-carrot'>
            {trendLibrary?.trendHashMap[trend.trendId]?.showItems ? (
              <ChevronUpIcon width='20' height='20' stroke='#647980' />
            ) : (
              <ChevronDownIcon width='20' height='20' stroke='#647980' />
            )}
          </div>
        </div>
        {trendLibrary?.trendHashMap[trend.trendId]?.showItems && (
          <div>
            <div style={{ paddingLeft: `${(indentLevel + 1) * 20}px` }}>
              {!isFiltered
                ? trend.childTrends?.map((childTrend, childIndex) =>
                    renderTrend(childTrend, index + childIndex + 1, indentLevel + 1),
                  )
                : null}
              {trend.items?.map((item, itemIndex) => (
                <div
                  data-testid={`trend-${item.name}`}
                  key={item.name + itemIndex.toString()}
                  className='trend-library-content-frame-item'
                  onClick={() =>
                    handleItemIdClick(item.name, trend.trendId, item.id, !checkedItemsNames.includes(item.name))
                  }
                >
                  <Checkbox
                    isChecked={checkedItemsNames.includes(item.name)}
                    Label={item.name}
                    onItemSelect={() => handleCheckboxChange(item)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const itemTrendDetails = trendItems?.map((value: Trend, index: number) => renderTrend(value, index));

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    dispatch(clearSearchFilter());
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <Menu as='div' className='toggle-dropdown-container' ref={dropdownRef}>
      <div>
        <Menu.Button onClick={handleButtonClick}>{buttonContent}</Menu.Button>
      </div>
      {isDropdownOpen && (
        <div
          className='trend-dropdown-container'
          style={
            chartIndex === 2
              ? { bottom: '0', marginBottom: '-90px', marginRight: '30px' }
              : chartIndex === 3
              ? { bottom: '40px' }
              : undefined
          }
        >
          <div className='trend-library-dropdown-label-container'>
            <div className='trend-library-option-label'>Trend library</div>
          </div>
          <div className='trend-library-dropdown-label-container'>
            <div className='trend-library-sub-header'>Selected trends</div>
          </div>
          {trendsGroupSelection.length > 0 && (
            <div className='trend-library-selected-item-text'>
              {trendsGroupSelection[chartIndex].selectedTrendsArray.map((trend, index) => (
                <div key={index} className='trend-library-selected-trend-item'>
                  <Checkbox
                    isChecked={checkedItemsNames.includes(trend.name)}
                    Label={trend.name}
                    onItemSelect={() => handleCheckboxChange(trend)}
                  />
                </div>
              ))}
            </div>
          )}
          <div className='trend-library-content-search'>
            <SearchIcon className='trend-library-search-icon' width='20' height='20' stroke='#647980' />
            <input
              className='input-with-icon'
              placeholder='Search'
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyPress}
            />
          </div>
          <div className='trend-library-item-details flex-grow flex-shrink'>{itemTrendDetails}</div>
        </div>
      )}
    </Menu>
  );
};

export default TrendLibraryGrouped;
