import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

interface TimelineScrollbarProps {
  scrollbarRef: React.RefObject<HTMLDivElement>;
  handleWidth: number;
  handlePosition: number;
  moveHandle: (direction: 'left' | 'right') => void;
  handleMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const TimelineScrollbar: React.FC<TimelineScrollbarProps> = ({
  scrollbarRef,
  handleWidth,
  handlePosition,
  moveHandle,
  handleMouseDown,
}) => {
  return (
    <div className='timeline-scrollbar-container'>
      <div className='timeline-scrollbar-button' onClick={() => moveHandle('left')}>
        <ChevronLeftIcon />
      </div>
      <div className='timeline-scrollbar' ref={scrollbarRef}>
        <div
          className='timeline-scrollbar-inner'
          style={{ left: `${handlePosition}px`, width: `${handleWidth}px` }}
          onMouseDown={handleMouseDown}
        />
      </div>
      <div className='timeline-scrollbar-button' onClick={() => moveHandle('right')}>
        <ChevronRightIcon />
      </div>
    </div>
  );
};

export default TimelineScrollbar;
