import React from 'react';
import './HorizontalTabsV2.scss';

interface tabDetails {
  key: number;
  value: string;
  active: boolean;
  imgSrc: string;
}
interface HorizontalTabsProps {
  data: tabDetails[];
  tabClicked: (id: number) => void;
}
export const HorizontalTabsV2 = (props: HorizontalTabsProps) => {
  const { data, tabClicked } = { ...props };
  return (
    <div className='horizontal-tabs-v2'>
      {data?.map((tab: tabDetails) => {
        const { value, key, active, imgSrc } = { ...tab };

        const className = active ? 'tab-v2 active-v2' : 'tab-v2 inactive-v2';
        return (
          <div
            key={key}
            className={className}
            onClick={() => {
              tabClicked(key);
            }}
          >
            <img
              src={imgSrc}
              className={
                active
                  ? 'ui-icon-button-toolbar-icon ui-icon-button-toolbar-icon-active semi-bold'
                  : 'ui-icon-button-toolbar-icon semi-bold'
              }
            />
            {value}
          </div>
        );
      })}
    </div>
  );
};
