import React from 'react';
import WidgetData from './WidgetData';
import GroupStatusWidgetChart from './GroupStatusWidgetChart';
import './GroupStatusWidgets.scss';
import { downloadCSV, generateCSVContent } from '../../common/CSVUtils/csvUtils';
import CardHeader from '../../common/CardHeader/CardHeader';
import FeaturedIcon from '../../common/No/FeaturedIcon';

import Loader from '../../common/Loader/Loader';

export const groupStatusWidgetColors = [
  {
    color: '#003747',
  },
  {
    color: '#004EB3',
  },
  {
    color: '#3A7E73',
  },
  {
    color: '#6BBBAE',
  },
  {
    color: '#FFBF3F',
  },
  {
    color: '#EF6820',
  },
  {
    color: '#DC4405',
  },
];

const GroupStatusWidgets = ({
  loading,
  failedToLoad,
  widgetHeader,
  widgetChartData,
  chartId,
  chartValue,
  isValueSumVisible,
  csvData = null,
}) => {
  const handleDownloadClick = () => {
    const data = csvData || widgetChartData;
    if (!data || data.length === 0) {
      alert('There is no data to download.');
      return;
    }

    const headers = Object.keys(data[0]);
    const csvContent = generateCSVContent(
      headers.map((header) => ({ accessor: header })),
      data,
    );

    downloadCSV(csvContent, `${widgetHeader}.csv`);
  };

  const isDataAvailable = widgetChartData && widgetChartData.length > 0;

  return (
    <div className='group-status-widget-card'>
      <CardHeader value={widgetHeader} icon='verticalDots' onClick={handleDownloadClick} showDropdown={true} />
      <div style={{ padding: '0px 13px' }}>
        <div className='card-header-divider' />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {isDataAvailable ? (
            <div className='group-status-widget-body'>
              <div className='flex flex-row flex-grow flex-shrink' style={{ width: '100%' }}>
                <div className='flex flex-grow flex-shrink flex-column' style={{ width: '40%', alignItems: 'center' }}>
                  <GroupStatusWidgetChart
                    chartData={widgetChartData}
                    chartId={chartId}
                    chartValue={chartValue}
                    isValueSumVisible={isValueSumVisible}
                  />
                </div>
                <div className='flex flex-grow flex-shrink flex-column' style={{ width: '60%', overflowY: 'auto' }}>
                  <div className='widget-data'>
                    {widgetChartData.map((item, index) => (
                      <WidgetData
                        key={index}
                        id={item.id}
                        name={item.name}
                        value={item.count}
                        color={groupStatusWidgetColors[index % groupStatusWidgetColors.length].color}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='group-widgets-no-data'>
              <FeaturedIcon />
              {failedToLoad ? (
                <>
                  <p className='group-widgets-no-data-text'> Failed to Load</p>
                  <p className='group-widget-failed-to-load-text'>
                    Some data requirements failed to load Please refresh your browser and try again. If the problem
                    persists please contact the support team.
                  </p>
                </>
              ) : (
                <p className='group-widgets-no-data-text'>No Data Found</p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GroupStatusWidgets;
