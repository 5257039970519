import React from 'react';
import { ReactComponent as Eye } from '../../../images/eye.svg';
import { ReactComponent as Graph } from '../../../images/graph-icon.svg';

import './UIEyeAndFilterButtons.scss';

function UIEyeButton(props) {
  return <div className='card-viewer-button'>{props.type === 'graph' ? <Graph /> : <Eye />}</div>;
}

export default UIEyeButton;
