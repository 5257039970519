import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchNotificationTypeNameAsync } from './NotificationService';

interface NotificationTypeValue {
  Id: number;
  Name: string;
}

interface NotificationTypeListState {
  Values: NotificationTypeValue[];
}

const initialState: NotificationTypeListState = {
  Values: [],
};

// Async thunk to fetch initial notificationType data
const fetchNotificationTypesAsync = createAsyncThunk('NotificationTypesSlice/fetchNotificationTypeName', async () => {
  const response = await fetchNotificationTypeNameAsync();

  return response;
});

const notificationTypesSlice = createSlice({
  name: 'notificationTypes',
  initialState,
  reducers: {
    updateSelectedNotificationType(state, action: PayloadAction<NotificationTypeListState>) {
      state.Values = action.payload.Values;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationTypesAsync.fulfilled, (state, action) => {
      if (action?.payload?.Values === undefined || action.payload.Values.length === 0) {
        return;
      }

      state.Values = action.payload?.Values.map(
        (x) =>
          ({
            Id: x.Id,
            Name: x.Name,
          }) as NotificationTypeValue,
      );
    });
  },
});

export { fetchNotificationTypesAsync, NotificationTypeValue };
export const { updateSelectedNotificationType } = notificationTypesSlice.actions;

export default notificationTypesSlice.reducer;
