import React from 'react';
import './UILabelItem.css';

export interface UILabelItemProps {
  id: string;
  title: string;
  value: string;
}

function UILabelItem(props: UILabelItemProps) {
  const { title, value } = { ...props };

  return (
    <div className="card-viewer-data">
      <p className="card-viewer-title">{title} </p>
      <p className="card-viewer-value">{value}</p>
    </div>
  );
}

export default UILabelItem;
