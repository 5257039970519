import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface NotificationFilterState {
  currentFilterId: number | null;
}

const initialState: NotificationFilterState = {
  currentFilterId: null,
};

export const notificationFilterSlice = createSlice({
  name: 'notificationFilter',
  initialState,
  reducers: {
    setCurrentFilterId(state, action: PayloadAction<number | null>) {
      state.currentFilterId = action.payload;
    },
    clearCurrentFilterId(state) {
      state.currentFilterId = null;
    },
  },
});

export const { setCurrentFilterId, clearCurrentFilterId } = notificationFilterSlice.actions;

export const selectCurrentFilterId = (state: RootState) => state.notificationFilter.currentFilterId;

export default notificationFilterSlice.reducer;
