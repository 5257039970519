import { DiagramTypes } from './models/DiagramTypes';
import AirBalancedDark from './dark-images/air-balanced.svg';
// import AirBalancedLight from './light-images/air-balanced.svg';
import ConventionalDark from './dark-images/conventional.svg';
// import ConventionalLight from './light-images/conventional.svg';
import LowProfileDark from './dark-images/low-profile.svg';
// import LowProfileLight from './light-images/low-profile.svg';
import Mark2Dark from './dark-images/mark-2.svg';
import Mark2Light from './light-images/mark-2.svg';
import PCPDark from './dark-images/pcp.svg';
import PCPLight from './light-images/pcp.svg';
import ReverseMarkDark from './dark-images/reverse-mark.svg';
// import ReverseMarkLight from './light-images/reverse-mark.svg';
import RotaflexDark from './dark-images/rotaflex.svg';
import RotaflexLight from './light-images/rotaflex.svg';
import ESPDark from './dark-images/esp.svg';
import ESPLight from './light-images/esp.svg';
import PlungerLiftDark from './dark-images/plunger-lift.svg';
import PlungerLiftLight from './light-images/plunger-lift.svg';
import InjectionDark from './dark-images/injection.svg';
// import InjectionLight from './light-images/injection.svg';
import GasLiftDark from './dark-images/gas-lift.svg';
import GasLiftLight from './light-images/gas-lift.svg';
import EFMDark from './dark-images/efm.svg';
// import EFMLight from './light-images/efm.svg';
import GasLiftManagerDark from './dark-images/gas-lift-manager.svg';
// import GasLiftManagerLight from './light-images/gas-lift-manager.svg';
// import GasFlowMonitorDark from './dark-images/gas-flow-monitor.svg';
// import GasFlowMonitorLight from './light-images/gas-flow-monitor.svg';
import MultiWellPlungerLiftDark from './dark-images/multi-well-plunger-lift.svg';
// import MultiWellPlungerLiftLight from './light-images/multi-well-plunger-lift.svg';
import TotalFlowTankDark from './dark-images/total-flow-tank.svg';
// import TotalFlowTankLight from './light-images/total-flow-tank.svg';
import LiquidFlowMeterDark from './dark-images/liquid-flow-meter.svg';
// import LiquidFlowMeterLight from './light-images/liquid-flow-meter.svg';
// import PulseAccumulatorDark from './dark-images/pulse-accumulator.svg';
// import PulseAccumulatorLight from './light-images/pulse-accumulator.svg';
// import RegisterViewDark from './dark-images/register-view.svg';
// import RegisterViewLight from './light-images/register-view.svg';
import NOVCoreLiftControllerDark from './dark-images/nov-core-lift-controller.svg';
// import NOVCoreLiftControllerLight from './light-images/nov-core-lift-controller.svg';
import HydraulicStrokingUnitDark from './dark-images/hydraulic-stroking-unit.svg';
// import HydraulicStrokingUnitLight from './light-images/hydraulic-stroking-unit.svg';
import UnicoLRPDark from './dark-images/unico-lrp.svg';
// import UnicoLRPLight from './light-images/unico-lrp.svg';
import ChemicalInjectionPumpDark from './dark-images/chemical-injection-pump.svg';
import ChemicalInjectionPumpLight from './light-images/chemical-injection-pump.svg';
import ChemicalInjectionPumpDigiUltraDark from './dark-images/chemical-injection-pump-digi-ultra.svg';
// import ChemicalInjectionPumpDigiUltraLight from './light-images/chemical-injection-pump-digi-ultra.svg';
// import PlungerAssistedGasLiftDark from './dark-images/plunger-assisted-gas-lift.svg';
// import PlungerAssistedGasLiftLight from './light-images/plunger-assisted-gas-lift.svg';
import FacilityDark from './dark-images/facility.svg';
import FacilityLight from './light-images/facility.svg';
// import ValveControlDark from './dark-images/valve-control.svg';
// import ValveControlLight from './light-images/valve-control.svg';
// import PIDDark from './dark-images/pid.svg';
// import PIDLight from './light-images/pid.svg';
import TundraSSIDark from './dark-images/tundra-ssi.svg';
// import TundraSSILight from './light-images/tundra-ssi.svg';
import JetPumpDark from './dark-images/jet-pump.svg';
// import JetPumpLight from './light-images/jet-pump.svg';
import PulseAccumulatorDark from './dark-images/pulse-accumulator.svg';
// import PulseAccumulatorLight from './light-images/pulse-accumulator.svg';
import GasFlowMonitorDark from './dark-images/gas-flow-monitor.svg';
// import GasFlowMonitorLight from './light-images/gas-flow-monitor.svg';

export const OverlayImagesDark = {
  [DiagramTypes.AirBalanced]: AirBalancedDark,
  [DiagramTypes.Conventional]: ConventionalDark,
  [DiagramTypes.LowProfile]: LowProfileDark,
  [DiagramTypes.Mark2]: Mark2Dark,
  [DiagramTypes.Pcp]: PCPDark,
  [DiagramTypes.ReverseMark]: ReverseMarkDark,
  [DiagramTypes.Rotaflex]: RotaflexDark,
  [DiagramTypes.Esp]: ESPDark,
  [DiagramTypes.PlungerLift]: PlungerLiftDark,
  [DiagramTypes.Injection]: InjectionDark,
  [DiagramTypes.GasLift]: GasLiftDark,
  [DiagramTypes.Efm]: EFMDark,
  [DiagramTypes.GasLiftManager]: GasLiftManagerDark,
  [DiagramTypes.GasFlowMonitor]: GasFlowMonitorDark,
  [DiagramTypes.MultiWellPlungerLift]: MultiWellPlungerLiftDark,
  [DiagramTypes.TotalFlowTank]: TotalFlowTankDark,
  [DiagramTypes.LiquidFlowMeter]: LiquidFlowMeterDark,
  [DiagramTypes.PulseAccumulator]: PulseAccumulatorDark,
  // [DiagramTypes.RegisterView]: RegisterViewDark,
  [DiagramTypes.NOVCoreLiftController]: NOVCoreLiftControllerDark,
  [DiagramTypes.HydraulicStrokingUnit]: HydraulicStrokingUnitDark,
  [DiagramTypes.UnicoLRP]: UnicoLRPDark,
  [DiagramTypes.ChemicalInjectionPump]: ChemicalInjectionPumpDark,
  [DiagramTypes.ChemicalInjectionPumpDigiUltra]: ChemicalInjectionPumpDigiUltraDark,
  // [DiagramTypes.PlungerAssistedGasLift]: PlungerAssistedGasLiftDark,
  [DiagramTypes.Facility]: FacilityDark,
  // [DiagramTypes.ValveControl]: ValveControlDark,
  // [DiagramTypes.PID]: PIDDark,
  [DiagramTypes.TundraSSi]: TundraSSIDark,
  [DiagramTypes.JetPump]: JetPumpDark,
};

export const OverlayImagesLight = {
  // [DiagramTypes.AirBalanced]: AirBalancedLight,
  // [DiagramTypes.Conventional]: ConventionalLight,
  // [DiagramTypes.LowProfile]: LowProfileLight,
  [DiagramTypes.Mark2]: Mark2Light,
  [DiagramTypes.Pcp]: PCPLight,
  // [DiagramTypes.ReverseMark]: ReverseMarkLight,
  [DiagramTypes.Rotaflex]: RotaflexLight,
  [DiagramTypes.Esp]: ESPLight,
  [DiagramTypes.PlungerLift]: PlungerLiftLight,
  // [DiagramTypes.Injection]: InjectionLight,
  [DiagramTypes.GasLift]: GasLiftLight,
  // [DiagramTypes.Efm]: EFMLight,
  // [DiagramTypes.GasLiftManager]: GasLiftManagerLight,
  // [DiagramTypes.GasFlowMonitor]: GasFlowMonitorLight,
  // [DiagramTypes.MultiWellPlungerLift]: MultiWellPlungerLiftLight,
  // [DiagramTypes.TotalFlowTank]: TotalFlowTankLight,
  // [DiagramTypes.LiquidFlowMeter]: LiquidFlowMeterLight,
  // [DiagramTypes.PulseAccumulator]: PulseAccumulatorLight,
  // [DiagramTypes.RegisterView]: RegisterViewLight,
  // [DiagramTypes.NOVCoreLiftController]: NOVCoreLiftControllerLight,
  // [DiagramTypes.HydraulicStrokingUnit]: HydraulicStrokingUnitLight,
  // [DiagramTypes.UnicoLRP]: UnicoLRPLight,
  [DiagramTypes.ChemicalInjectionPump]: ChemicalInjectionPumpLight,
  // [DiagramTypes.ChemicalInjectionPumpDigiUltra]: ChemicalInjectionPumpDigiUltraLight,
  // [DiagramTypes.PlungerAssistedGasLift]: PlungerAssistedGasLiftLight,
  [DiagramTypes.Facility]: FacilityLight,
  // [DiagramTypes.ValveControl]: ValveControlLight,
  // [DiagramTypes.PID]: PIDLight,
  // [DiagramTypes.TundraSSi]: TundraSSILight,
};
