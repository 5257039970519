import api from '../../services/api';

export const fetchRodliftWellStatusInitialState = async (assetId: string) => {
  return await api
    .get(`api/AssetStatus?assetId=${assetId}`, {
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(function (response: any) {
      return response.data;
    });
};
