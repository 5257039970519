const PressureProfileChartColors = [
  {
    id: 7,
    name: 'Temperature Curve',
    color: '#FF0000',
  },
  {
    id: 8,
    name: 'Production Fluid Pressure Curve',
    color: '#0000FF',
  },
  {
    id: 5,
    name: 'Reservoir Fluid Hydrostatic Curve',
    color: '#800080',
  },
  {
    id: 6,
    name: 'Kill Fluid Hrydrostatic Curve',
    color: '#008000',
  },
  {
    id: 4,
    name: 'Gas Injection Pressure Curve',
    color: '#00CED1',
  },
  {
    id: 29,
    name: 'Injection Rate for Critical Velocity Curve',
    color: '#FF7F50',
  },
  {
    id: 25,
    name: 'Temperature Survey Curve',
    color: '#808000',
  },
  {
    id: 26,
    name: 'Pressure Survey Curve',
    color: '#000080',
  },
];

export default PressureProfileChartColors;
