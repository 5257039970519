import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCardCoordinatesAsync } from './ESPAnalysisService';

interface CardCoordinate {
  x: number;
  y: number;
}

interface CardTrend {
  id: number;
  name: string;
  coordinates: CardCoordinate[];
  curveTypeId: number;
}

interface Card {
  cardDate: string;
  cardTrends: CardTrend[];
}

interface Cards {
  [cardDate: string]: Card;
}

interface CardCoordinatesState {
  values: Cards;
  assetId: string;
  isLoading: boolean;
}

const fetchEspAnalysisCardCoordinatesAsync = createAsyncThunk(
  'ESPAnalysis/fetchCardCoordinates',
  async (input: { assetId: string; cardDate?: string }) => {
    const response = await fetchCardCoordinatesAsync(input.assetId, input.cardDate);

    return response;
  },
);

const initialState: CardCoordinatesState = {
  values: {},
  assetId: '',
  isLoading: false,
};

const espAnalysisCardCoordinatesSlice = createSlice({
  name: 'ESPAnalysis/CardCoordinatesSlice',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEspAnalysisCardCoordinatesAsync.pending, (state, action) => {
      // If fetching data for new asset id then clear slice state.
      const assetId = action.meta.arg.assetId;
      const existingAssetId = state.assetId;

      if (assetId != existingAssetId) {
        return { ...initialState, isLoading: true };
      }
    });
    builder.addCase(fetchEspAnalysisCardCoordinatesAsync.fulfilled, (state, action) => {
      const cardDate = action.meta.arg.cardDate;
      const assetId = action.meta.arg.assetId;

      if (!cardDate) {
        return;
      }

      const card: Card = {
        cardDate: cardDate,
        cardTrends: action.payload.values?.map((cardTrend: any) => {
          const cardName = cardTrend.name ? cardTrend.name : cardTrend.displayName;
          return {
            id: cardTrend.id,
            name: cardName,
            coordinates: cardTrend.coordinates,
            curveTypeId: cardTrend.curveTypeId,
          } as CardTrend;
        }),
      };

      state.values[cardDate] = card;
      state.assetId = assetId;
      state.isLoading = false;
    });
    builder.addCase(fetchEspAnalysisCardCoordinatesAsync.rejected, (state, action) => {
      state.isLoading = true;
      console.error(action.error.message);
    });
  },
});

export { fetchEspAnalysisCardCoordinatesAsync };
export const { setInitialState } = espAnalysisCardCoordinatesSlice.actions;
export default espAnalysisCardCoordinatesSlice.reducer;
