import React from 'react';
import './Splash.scss';

const Splash = () => {
  return (
    <div data-testid='onboarding-shell' className='splash-shell'>
      <div className='content'>
        <p className='logo'>
          <svg width='1376' height='153' viewBox='0 0 1376 153' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_925_124193)'>
              <path d='M650.385 20.49H622.935V123.03L636.165 152.77H704.375V130.22H650.385V20.49Z' fill='white' />
              <path d='M749.865 20.49H722.415V152.77H820.735V130.22H749.865V20.49Z' fill='white' />
              <path
                d='M891.485 20.49L859.795 69.78L828.255 20.49H797.595L846.175 92.76V152.77H873.625V92.76L922.195 20.49H891.485Z'
                fill='white'
              />
              <path
                d='M506.205 95.67L534.215 30.44L545.125 0.5H522.665L453.805 152.77H465.625H481.605H513.095V130.22L491.215 130.48L497.005 117.06L506.205 95.67Z'
                fill='white'
              />
              <path
                d='M545.125 0.5L534.215 30.44L577.115 130.43L553.215 130.7L554.305 152.77H614.205L545.125 0.5Z'
                fill='#DC4405'
              />
            </g>
            <defs>
              <clipPath id='clip0_925_124193'>
                <rect width='468.39' height='152.27' fill='white' transform='translate(453.805 0.5)' />
              </clipPath>
            </defs>
          </svg>
        </p>
        <p className='text'>Welcome to ALLY, your digital production partner.</p>
      </div>
    </div>
  );
};

export default Splash;
